h1 {
  background: center center no-repeat $primary-logo;
}

.btn:not(.fa-ellipsis-h) {  
  position: relative;
  display: inline-block;
  padding: 10px 12px;
  margin: 4px;
  text-align: center;
  vertical-align: middle;
  cursor: url(https://unpkg.com/nes.css/assets/cursor-click.png),pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  box-shadow: inset -4px -4px #adafbc;
  @include border-radius(0); 

  &.btn-primary {
    box-shadow: inset -4px -4px $primaryShadow;
  }

  &.btn-danger {
    box-shadow: inset -4px -4px $dangerShadow;
  }


  &::before {
    position: absolute;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    content: "";
    border-color: #212529;
    border-style: solid;
    border-width: 4px;    
    top: -4px;
    left: 0;
    border-right: 2px;
    border-left: 2px;
  }

  &::after {
    position: absolute;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    content: "";
    border-color: #212529;
    border-style: solid;
    border-width: 4px;    
    top: 0;
    left: -4px;
    border-top: 2px;
    border-bottom: 2px;
  }
}

//boutons
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    background-color: lighten($primary, 10%) !important;
    border-color: lighten($primary, 10%) !important;
    @include box-shadow(none !important);
  }
}

.card {
  border: 0;
}

/*.card-box {
    //background-color: darken($dark,10%); 
} */

.container-2-cols {
  >.col-md-6 {
    background: #fff !important;

    &:last-child {
      background: none;
      //background-color: transparent;
      background-size: contain;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$lightdark-alt+0,000000+100&0.65+0,0+100 */
        background: -moz-linear-gradient(left, $lightdark-alt 0%, rgba(0, 0, 0, 0) 5px);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(150, 150, 150, 0.616) 0%, rgba(0, 0, 0, 0) 5px);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(150, 150, 150, 0.65) 0%, rgba(0, 0, 0, 0) 10px);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }
}

h3,
h4 {
  color: $fontColor;
}

.modal-body,
.card {
  color: $fontColor;
}

.table-striped {
  tr:nth-child(odd) {
    background-color: darken($background, 15%) !important;
  }
  tr:nth-child(even) {
    background-color: darken($background, 10%) !important;
  }


  .modal-title  {
    font-size: 1.2em;
  }
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  font-size: 12px;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  font-size: 14px;
}
