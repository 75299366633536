h1 {
  background: center center no-repeat $primary-logo;
  background-size: 45px auto;
  padding: 10px 0 10px 60px;
  background-position: 10px center;
}

//boutons
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    background-color: lighten($primary, 10%) !important;
    border-color: lighten($primary, 10%) !important;
    @include box-shadow(none !important);
  }
}

.btn-outline-primary {
  color: $primary  !important;;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    background-color: lighten($primary, 10%) !important;
    color: #fff !important;;
    border-color: lighten($primary, 10%) !important;
    @include box-shadow(none !important);
  }
}

lp-login, lp-maintenance  {
  background: url("../../../assets/img/login-background.jpg") 100% 100% no-repeat #ebeff2;;

  .logos {
    height: 170px;
    background: $primary-logo no-repeat center center;
  }
}


.card {
  border: 0;
}

/*.card-box {
    //background-color: darken($dark,10%); 
} */

.container-2-cols {
  >.col-md-6 {
    &:last-child {
      background: none;
      background-color: transparent;
      background-size: contain;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$lightdark-alt+0,000000+100&0.65+0,0+100 */
        background: -moz-linear-gradient(left, $lightdark-alt 0%, rgba(0, 0, 0, 0) 5px);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(150, 150, 150, 0.616) 0%, rgba(0, 0, 0, 0) 5px);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(150, 150, 150, 0.65) 0%, rgba(0, 0, 0, 0) 10px);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }
}

h3,
h4 {
  color: $fontColor;
}

.modal-body,
.card {
  color: $fontColor;
}

.btn-actions {
  padding: 10px 20px !important;
}

.modal-header {
  padding: 10px 20px;
}