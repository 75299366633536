::-moz-selection { background: #64b6b7; }
::selection { background: #64b6b7; }

/* =============
   Panels
============= */
.panel-group {
  .panel {
    margin-bottom: 20px;

    .panel-heading {
      h5 {
        margin: 0;
        color: $black;

        i {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          margin-top: -2px;
        }
      }

      .accordion-toggle {
        display: block;
      }

      &:hover {
        h4 {
          color: $primary;
        }
      }
    }

    .panel-heading+.panel-collapse {
      .panel-body {
        padding: 20px;

        p {
          margin: 0px;
        }

        p+p {
          margin-top: 15px;
        }
      }
    }

    &.open {
      .panel-heading {
        .accordion-toggle {
          /*&:before {
            content: '\f056';
          }*/
        }
      }
    }
  }

  .panel-heading {
    padding: 12px 26px;
    cursor: pointer;
  }
}


/* =============
   Theme switcher
============= */
.lpSwitchTheme {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  > div {
    white-space: normal;
  }

  .btn {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    @include transition(all, 0.1s, linear);
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
    color: $dark !important;
  }

  span {
    display: inline-block;
    vertical-align: middle;

    &.theme {
      cursor: pointer;
      height: 24px;
      width: 30px;
      margin-right: 5px;
      border: 1px solid $black;
      font: 0/0 a;
      text-shadow: none;
      color: transparent;
      background-color: $white;
      background: linear-gradient(to right bottom, $white 0%, $white 46%, $primary 52%, $light5 54%, $light5 100%);
    }

    &:last-child {
      margin-right: 15px;
    }
  }
}

/* =======================
   Theme switcher language
======================= */
.flagOne {
  height: 30px;
  cursor: pointer;
  padding-left: 0.65rem;
}

.flag {
  height: 30px;
  cursor: pointer;
}

/* Data tables */
.owl-dialog-container {
  padding: 0;

  .owl-dt-dialog-container {
    margin: 0;
  }
}

div.dataTables_paginate {
  ul.pagination {
    margin-top: 30px;
  }
}

.dataTables_wrapper.container-fluid {
  max-width: 100%;
}

div.dataTables_info {
  padding-top: 38px;
}

.dt-buttons {
  float: left;
}

div#datatable-buttons_info {
  float: left;
}


.close-btn {
  position: absolute;
  font-size: 5em;
  right: -50px;
  top: 0;
  line-height: 0.5em;
  color: darken($white, 5%);
}





.sep-input {
  line-height: 38px;
}

.input {
  display: inline;
  margin-left: 50px;
  float: left;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.delayed {
  color: $red;
  font-size: 1.2em;
  display: inline-block;
  vertical-align: top;
  height: 20px;
  margin-top: -5px;
}

.title {
  display: inline-block;
  padding-right: 25px;
  vertical-align: baseline;
  font-size: 1.2em;
  color: $white;
  margin: 0;
  line-height: 50px;

  .scheduler-icon {
    font-size: 2.05em;
    display: inline-block;
    vertical-align: top !important;
    height: 40px;
    margin-top: 5px;
    margin-right: 10px;
    color: $white;
  }

  .scheduler-text {
    font-size: 1.2em;
  }
}

.unaffected {
  font-style: italic;
}

.hasIconClass {
  padding-right: 45px !important;
}

.valid-detailForm {
  border: 0;
  background: transparent;
  font-size: 2.4em;
  line-height: 1em;
  height: 31px;
  cursor: pointer;

  &:hover{
    color: $primary;
  }
}

// ************

.lign1 {
  width: 100%;
  float: left;
  display: inline-block;
  margin-bottom: 3px !important;
}

.lignItem {
  float: left;

  label {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.display-more {
  width:100%;
  float:right;
  span{
    color: cornflowerblue;
    float: right;
    &:hover{
      cursor: pointer;
    }
    &:active{
      color: blueviolet;
    }
  }
}

[data-icon]:before {
  font-family: "FontAwesome" !important;
  content: "";
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mat-slide-toggle {
  padding-left: 10px;
}



.dhx_timeline_scale_header  {
  border: none;
}

lp-application-item-actions {
  .overlay {
    z-index: 1050 !important;
  }
}




.pref-btn {
  height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 38px;
  font-size: 1.5em;
  padding: 0;
  width: 38px;

  .vega-duplicate {
    font-size: 1.3em;
    line-height: 36px;
  }

  &.loader-min {
    display: inline-block;
    vertical-align: middle;
  }
}



/* drag and drop de liste */
.cdk-drop-list {

  .cdk-drop-placeholder {
    background: lighten($lightdark-alt, 5%);
    min-height: 42px;
    @include transition(transform, 250ms, cubic-bezier(0, 0, 0.2, 1));
    @include vertical-align-middle();
    justify-content: left;
    color: $white;
    padding-left: 15px;
  }

  .cdk-drag {
    @include transition(transform, 250ms, cubic-bezier(0, 0, 0.2, 1));

    .cdk-drag-handle {
      cursor: grab;
      background: $icon-drag no-repeat;
      background-position: right top 5px;
      opacity: 0.25;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 1;
      }
    }
  }

  /* Animate an item that has been dropped. */
  .cdk-drag-animating {
    @include transition(transform, 300ms, cubic-bezier(0, 0, 0.2, 1));
  }

  &.cdk-drop-list-dragging {
    * {
      cursor: grabbing !important;
    }
  }

  &.list-preference-menu {
    min-height: 360px;

    .cdk-drag, .cdk-drop-placeholder {
      padding-left: 35px;
      cursor: grab;
      position: relative;

      * {
        cursor: grab;
      }

      .content {
        vertical-align: middle;
        height: 36px;
        overflow: hidden;
        line-height: 36px;
      }

      i {
        font-size: 2em;
        position: absolute;
        top: 2px;
        left: 2px;
      }      
    }
  }
}

lp-ui-sort-list {
  .cdk-drag {
    position: relative;
    .cdk-drag-handle {
      position: absolute;
      top: calc(50% - 17px);
      right: 5px;
      width: 25px;
      height: 34px;
    }
  }
}

.pricing-details-config-panel {
  .panel-body {
    padding: 5px !important;
  }
}

.debug-modal .panel-body {
  padding: 0 !important;

  pre {
    margin: 0;    
    padding: 5px;
    border: 0;
  }
}

.jsoneditor {
  border-color: #424242;

  .jsoneditor-menu {
    background: #424242;
    border-bottom-color: #424242;
    
    button {
      opacity: 0.8;
      color: #fff;
    }

  }
}

.bulk-add-row{
  right: 0px;
  position: absolute;
  margin: 2px 10px 0 0;
}

/* =============
  Report List
============= */

lp-report-list {
  datatable-body-cell {
    padding: 0 !important;

    .label-print-report {
      line-height: 33px;
      cursor: pointer;
    }

    .print-list-item {
      font-size: 28px !important;
      margin-left: -8px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}


/* =============
  Application item detail
============= */

.application-item-detail {

  i {
    width: 42px;
    text-align: center;  
  }
}
lp-application-item-details {
  position: relative;

  .btn-config,
  .btn-export, 
  .btn-import {
    font-size: 2em;
    float: right;
    margin-top: -4px;
    margin-right: 10px;
    margin-left: 5px;
  }
  .btn-import {
    margin-top: -2px;
  }

  .btn-config {
    margin-top: -5px;
  }

  .importBolck {
    height: 0;
    overflow: hidden;

    .importBolck, .btnUpload {
      height: 250px;
    }
    .btnUpload {
      display: flex;
      align-items: center;
      justify-content: center;
      
    flex-direction: column;
    }

    &.show {
      height: 250px;      
    }
  }

  .detail-config-tab {
    top: 47px;
    right: 0;
  }
}

.detail-config-tab {
  position: absolute;
  padding: 10px;
  z-index: 3;
  width: 450px;
  top: 58px;
  right: 18px;
  max-height: calc(100vh - 166px);
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 18px 0px #8c8c8c;
  -webkit-box-shadow: 0px 0px 18px 0px #8c8c8c;
  -moz-box-shadow: 0px 0px 18px 0px #8c8c8c;
}


/* =============
   PROGRESS BAR
============= */

lp-progress-bar {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      width: 750px;
      display: block;
      background: #ffffff;
      border-radius: 4px;      

      .label {
        font-weight: bold;
        font-size: 1.1em;
        text-align: center;
      }
    }
  }
}

lp-widget {
  lp-card-content.p-3 {
    padding: 0 !important;

    .card-header {
      border-radius: 0;
    }
  }
}


/* =============
  MATERIAL BADGE
============= */

.mat-badge-bottom .mat-badge-content {
  top: auto !important;  
  bottom: -2px;
  right: 2px !important;
  background-color: $danger;
  z-index: 1;
}


/* =============
  MATERIAL BADGE
============= */

.list-spooler {
  @include list-bottom-right(520px);
  
  .item {
    @include list-bottom-right-item;
    border-left: 10px solid $primary;
    position: relative;
    
    /* en attente */
    &.status-01,
    /* en attente spooler impression */
    &.status-001,
    /* A faire */
    &.status-20
     {
      border-color: lighten($inverse,20%);
    }
    
    /* en cours */
    &.status-02 {
      border-color: $light-blue;
      animation: blinkingBackground 2s infinite;
    }
    
    /* différé */
    &.status-03 {
      border-color: $dark;
    }

    /* Réalisé */
    &.status-21,
    /* Terminé */
    &.status-90
     {
      border-color: $inverse;
    }

    /* Echoué */
    &.status-92
     {
      border-color: $danger;
    } 

    /* A supprimer */
    &.status-91,
    &.status-99
     {
      border-color: #1d1d1d;
    } 
    .rounded-circle {
      width: 32px;
      height: 32px;
      display: flex;
      padding: 0;      
      justify-content: center;
      align-items: center;
    }

    .loader {
      width: 100%;
      height: 100%;    
      position: absolute;
      top: 0;
      left: 0;
      background: rgba($white, 0.5);
      z-index: 1;

    }
  }
}

@keyframes blinkingBackground{
  0%		{ border-color: lighten($light-blue, 5%);}
  50%		{ border-color: darken($light-blue, 5%);}
  100%	{ border-color: lighten($light-blue, 5%);}
}

@keyframes listSpoolerOpened {
  0%		{ height :0; overflow: hidden;}
  99%		{ height :80vh; overflow: hidden;} 
  100%		{ height :auto; overflow: auto;}  
}

lp-top-banner {
  .mat-badge-content {
    background-color: $primary;
    top: -4px !important;
    right: -11px !important;
  }
 }

 lp-sub-api {
  lp-button-cancel {    
    position: absolute;
    z-index: 1;
    right: 44px;
    margin-top: 8px;

    a {
      padding: 0.175rem 0.55rem !important;
    }
  }

  .panel-group {
    padding: 0;
  }
 }
 
lp-address{
  position: relative;

  .icon {  
    &:before {
      position: absolute;
      top: 9px;
      left: 6px;
      color: $muted;
      z-index: 1;
      font-size: 1.75em;
    }
  }

  i + input {
    padding-left: 36px !important;
  }
    
  .loader-search {
    top: 7px !important;
    left: 5px !important;
    z-index: 2;
  }
}

.menu-overlay {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  left: 50px;
  top: 53px;
  width: calc(100% - 50px);
  height: calc(100% - 53px);
}


.list-history {
  @include list-bottom-right(900px);

  .item {
    @include list-bottom-right-item;
    display: flex;

    div.event {
      display: flex;
      align-items: center;
      font-size: 1.5em;
      width: 60px;
      justify-content: center;

      &.show {
        background-color: $info;
        color: $white;
      }
      &.modify {
        background-color: $warning;
        color: $white;
      }
      &.delete {
        background-color: $danger;
        color: $white;
      }
      &.action {
        background-color: $lightdark1;
        color: $white;
      }
      &.login {
        background-color: $primary;
        color: $white;
      }
      &.changelocation {
        background-color: $purple;
        color: $white;
      }
      &.customization {
        background-color: $purple;
        color: $white;
      }
      &.add {
        background-color: $success;
        color: $white;
      }
    }

    div.text {
      width: calc(100% - 60px);
      text-align: left;      
      display: flex;      
      align-items: flex-start;
      flex-direction: column;

      a {
        text-decoration: none;
        font-weight: bold;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.btn-close-list {
  position: absolute;
  right: -48px;
  top: 1rem !important;
}

.historyHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.historyHeaderbutton {
  padding: 0 5px;
}


lp-signature-pad {
  cursor: pointer;

  &:active {
    cursor :crosshair;
  }
}

.btn-link {
  color: $primary;
  &:hover {    
    color: darken($primary, 10);
  }
}

lp-form-card {
  lp-active {
    label {
      width: 31px;
      height: 31px;      
      margin-right: 9px !important;
    }
  }

}

lp-report-parameters {
  .btn-save {
    background: transparent;
    border: 0;
    font-size: 3em;
    line-height: 1em;
    height: 31px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
  }
}

lp-ihubcheckplus-settings {
  .buttons {    
    bottom: 25px;
    position: fixed;    
    right: 45px;
    z-index: 2;
  }
}

.list-group {
  lp-mail-report {
    position: absolute;
    top: 3px;
    right: 1px;
    font-size: 0.75em;
  }
}
