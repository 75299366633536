.mat-snack-bar-container {
	color: rgba(255, 255, 255, .7);
	background: #323232;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
	border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin: 24px;
    max-width: 33vw;
    min-width : 344px;
    padding: 14px 16px;
    transform-origin: center;
}

.mat-simple-snackbar {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 14px
}

.mat-simple-snackbar-action {
	line-height: 1;
	font-family: inherit;
	font-size: inherit;
	font-weight: 500;
	flex-shrink:0;
	margin-left:-8px;
	margin-right:8px;

	button{
		max-height:36px;
		min-width:0
	}
}

.mat-simple-snackbar{
	display:flex;
	justify-content:space-between;
	align-items:center;
	line-height:20px;
	opacity:1
}
.cdk-visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	outline: 0;
	-webkit-appearance: none;
	-moz-appearance: none
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
	color: inherit;
	background: transparent
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
	color: #673ab7
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
	color: #ffd740
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
	color: #f44336
}

.mat-button.mat-primary[disabled],
.mat-button.mat-accent[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-icon-button.mat-primary[disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
	color: rgba(0, 0, 0, .26)
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
	background-color: #673ab7
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
	background-color: #ffd740
}

.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
	background-color: #f44336
}

.mat-button[disabled] .mat-button-focus-overlay,
.mat-icon-button[disabled] .mat-button-focus-overlay,
.mat-stroked-button[disabled] .mat-button-focus-overlay {
	background-color: transparent
}

.mat-button .mat-ripple-element,
.mat-icon-button .mat-ripple-element,
.mat-stroked-button .mat-ripple-element {
	opacity: .1;
	background-color: currentColor
}

.mat-button-focus-overlay {
	background: #000
}