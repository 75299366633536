/* =============
   Général
============= */

.alert-info {
  background-color: darken($background, 2%);
  border-color: $primary;
  color: $primary;
}

pre {
  background-color: darken($background, 2%);
  border: 1px solid lighten($background, 50%);
  color: $fontColor;
}

.barre{
  text-decoration: line-through
}

/* =============
   Panels
============= */

.panel-group {
  .panel {
    .panel-heading {
      //background-color: light($background,15%); 
      background-color: darken($background, 5%);

      h5 {
        color: $fontColor;
      }

      &:hover {
        h5 {
          color: $primary;
        }
      }
    }
  }
}

.overlay.nsm-overlay-open {
  background-color: rgba(255, 255, 255, 0.5);
}

.panel-heading+.panel-collapse {
  .panel-body {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: none;
    background-color: darken($background, 10%);
  }
}


/* =============
   Forms
============= */

/*inputs / select */
#{$lp-forms-components} {
  >span {
    .form-control {
      border: 2px solid lighten($background, 10%);
      background: darken($background, 5%);
      color: $inputColor;



      &:focus {
        border: 2px solid lighten($background, 27%) !important;
        background-color: $background;
        color: $inputColor;
      }

      &:disabled,
      &[readonly] {
        background-color: darken($background, 5%);
      }
    }
  }
}

lp-label-field {
  color: $fontColor;
}

// lp-fuel-gauge
lp-fuel-gauge input {
  background: darken($background, 5%) !important;
  border: 2px solid lighten($background, 10%);
}

// Checkbox and Radios

.checkbox {
  label {
    color: $fontColor;

    &:hover {
      &:before {
        background-color: lighten($primary, 10%);
        border-color: lighten($primary, 10%);
      }
    }
  }

  &.checked {
    label {
      &:before {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

/* switcher */
lp-switch-box {
  .switch-toggle.switch-candy {
    border: 1px solid lighten($background, 10%);
    background: darken($background, 5%);

    label {
      color: $fontColor;

      &:not(:checked):hover {
        border-color: $focusbtn;
      }

    }

    input:checked+label {
      color: $white;
    }

    input:checked~a {
      background: $primary;
    }
  }

  .disabled:before {
    background: rgba($background, 0.8);
  }
}

/* bouton radio */

.custom-radio {
  color: $fontColor;

  .custom-control-indicator {
    border: 1px solid lighten($muted, 19%);
  }

  &:hover {
    .custom-control-indicator {
      background-color: lighten($primary, 10%);
    }
  }

  input:checked+.custom-control-indicator {
    background-color: $primary !important;
  }
}


/* =============
  Menu
============= */

#menu {
  background-color: lighten($primary, 5%);
}

#menu .left-menu ul li a:hover {
  color: $primary;
}

ul {
  >li {
    >a {

      //color: $fontColor;    
      &:hover {
        color: lighten($primary, 10%);
      }
    }
  }
}

.navbar-custom {
  ul {

    >li,
    lp-ui-accordion-menu>li {
      >a {
        &:hover {
          color: $primary !important;
        }
      }
    }
  }
}

.under-menu {
  h2 {
    a {
      &:hover {
        color: $primary !important;
      }
    }
  }

  .col-right-menu {
    ul {
      li {
        &.active {
          i {
            color: lighten($primary, 15);
          }
        }

        i {

          &:hover {
            color: lighten($primary, 15);
          }
        }
      }
    }
  }
}




/* =============
   Tables
============= */

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td,
.table>thead>tr>th,
.table-bordered,
.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
  border-color: lighten($background, 27%) !important;
}


.table {
  color: $fontColor;

  tr:hover {
    input.form-control {
      background-color: transparent !important;
    }
  }

  input.form-control {
    background-color: darken($background, 5%);
    color: $fontColor;

    &:focus {
      color: $white;
      border-color: $primary !important;
    }

    &:disabled,
    &[readonly] {
      background-color: darken($background, 5%);
    }
  }
}

.table-hover>tbody>tr:hover {
  background-color: darken($background, 15%) !important;
}

.datatable-editable {
  input.form-control {
    background: transparent;
  }
}

.ngx-datatable.material {
  border: 1px solid darken($lightdark, 5%);
  background-color: darken($background, 5%);
  color: $fontColor;

  .datatable-header-inner {
    .datatable-header-cell {
      color: $fontColor;
    }
  }

  .datatable-header {
    border-color: darken($lightdark, 5%);
  }

  &:not(.cell-selection) .datatable-body-row {
    border-bottom: 1px solid darken($lightdark, 5%);

    .datatable-body-cell {
      color: $fontColor;
    }
  }

  &:not(.cell-selection) .datatable-body-row:hover,
  &:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: lighten($background, 5%);

    .datatable-body-cell {
      color: lighten($fontColor, 15%);
    }
  }

  datatable-summary-row {
    datatable-body-row {
      background-color: darken($background, 25%);

      datatable-body-cell {
        color: lighten($fontColor, 25%);
      }
    }
  }
}

.tooltip {
  opacity: 100;
}

datatable-row-wrapper:nth-child(odd) .datatable-row-group {
  background-color: $background;

}

/* =============
  modal
============= */
.nsm-content {
  background: darken($background, 5%);

  h5 {
    color: $muted;
  }
}

.container-2-cols {
  .modal-body {
    background-color: darken($background, 5%);
  }
}

.modal-content {
  background-color: darken($background, 5%);
  border: $muted;
  color: $fontColor;
}

#formulaire {
  background: transparent;
}

.sidebar-item.no-float {
  color: $fontColor;

  &:hover {
    color: $primary;
  }
}

.boolean-true,
.boolean-false {
  border-color: $fontColor;
}

.boolean-true {
  background-color: $fontColor;
}


/* =============
  scheduler
============= */
.body-scheduler {
  .scheduler {
    .component {
      .dhx_cal_container {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-top: none;
        background-color: darken($background, 10%);

        .dhx_cal_data {
          >table {
            width: 100%;
          }
        }
      }
    }
  }

  .filter-tab {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: none;
    background-color: darken($background, 10%);
    background-image: none;
  }

  .scheduler-details-icon-link {
    color: $fontColor;

    &:hover {
      color: $primary;
    }
  }
}

// TODO STC
.second-btn{
  right: 35px;
}
/******  scheduler colors *****/
.odd {
  background-color: $scheduler-odd !important;
}

.white {
  background-color: $scheduler-white !important;
}

.light-grey {
  background-color: $scheduler-light-grey !important;
}

.grey {
  background-color: $scheduler-grey !important;
}

.black {
  background-color: $scheduler-black !important;
}

.light-green {
  background-color: $scheduler-light-green !important;
}

.green {
  background-color: $scheduler-green !important;
}

.light-yellow {
  background-color: $scheduler-light-grey !important;
}

.yellow {
  background-color: $scheduler-yellow !important;
}

.light-orange {
  background-color: $scheduler-light-orange !important;
}

.orange {
  background-color: $scheduler-orange !important;
}

.light-red {
  background-color: $scheduler-light-red;
}

.red {
  background-color: $scheduler-red;
}

.light-blue {
  background-color: $scheduler-light-blue;
}

.blue {
  background-color: $scheduler-blue;
}

.theme-color {
  background-color: $scheduler-theme-color;
}

// tableau 
ngx-datatable {
  border: 1px solid #BDB1B1 !important;  

  datatable-header {
    background: $primary;

    .datatable-header-inner {
      datatable-header-cell {
        border-left-color:$lightdark-alt;
      }
    }
  }

  datatable-row-wrapper {
    datatable-body-row {
      border: 0;

      datatable-body-cell {
        border-left-color: $muted;  
        border-bottom-color: $muted;  
      }
    }
  }
}

.table-details tbody tr:nth-child(odd) {
  background-color: #1c1c1c;
}

.mat-slide-toggle-bar {
  background-color: rgb(80, 80, 80);
}