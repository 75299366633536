/**************
* GLOBAL
**************/
#{$lp-forms-components} {
  lp-label-field {
    min-height: 20px;
    width: 100%;
    display: block;
  }

  .input-group-btn {
    width: 38px;
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 6;
    height: calc(100% - 4px);
    button {
      height: auto;
      margin: 0;
      padding: 0px !important;
      @include border-radius(0);
      border: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      &:focus {
        @include box-shadow(none);
      }

      svg {
        margin-top: -4px;
      }
    }
  }
  .input-group-addon {
    width: 38px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 3;
    height: calc(100% - 4px);
    @include border-radius(0);
    i {
      padding-top: 11px;
    }
  }
  >span {
    position: relative;
    width: 100%;
    display: block;
    .form-control {
      position: relative;
      height: 38px;
      font-size: $base-font-size;
      max-width: 100%;
      @include box-shadow(none);
      @include transition(all, 200ms, linear);
      @include border-radius( 4px !important);
      &:focus {     
        outline: 0 !important;        
        @include box-shadow(none);
        border: 1px solid $primary !important;
        background-color: $white;
        color: $dark;
      }
      &.ng-invalid {
        @include box-shadow(none !important);
      }
      &:disabled,
      &[readonly] {
        //background-color: $white;        
        cursor: not-allowed;
      }
    }
    .form-control+.input-notif {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      &:before {
        font-family: 'FontAwesome';
        font-style: normal;
        font-weight: 400;
        content: $fa-var-times;
        position: absolute;
        left: 7px;
        top: calc(50% - 10px);
        color: $danger;
      }
    }
    .form-control.ng-touched.ng-invalid {
      padding-left: 25px;
    }
    .form-control.ng-touched.ng-invalid+.input-notif {
      display: block;
      height: 100%;
      &:before {
        content: $fa-var-times;
        color: $danger;
      }
    }
    .form-control.ng-touched.ng-invalid:focus+.input-notif {
      &:before {
        color: $warning;
        content: "\f071";
      }
    }
  }
  &.ng-invalid {
    >span {
      .form-control.ng-touched {
        //border: 2px solid $danger !important;
        &:before {
          font-family: 'FontAwesome';
          font-style: normal;
          font-weight: 400;
          content: $fa-var-times;
          position: absolute;
          left: 7px;
          top: calc(50% - 10px);
          color: $danger;
        }
        &:focus {
         //border: 2px solid $warning !important;
          &:before {
            color: $warning;
            content: "\f071";
            top: calc(50% - 10px);
          }
        }
      }
    }
  }  
  .input-group {

    &.input-group-zoom {

      input {
        //background: linear-gradient(to left, #e9ecef 50%, $white 50%);
        //background-size: 200% 100%;
        //background-position: left bottom;
        //transition: all .5s ease-out;
      }
    }
    

    .form-control.ng-touched.ng-invalid+.input-notif:before {
      //right: 55px;
      z-index: 3;
    }
    input {
      padding-right: 55px;

      &.canModifiy {
        padding-right: 70px;
      }
    }

    &.btn-loading {
      input {     
        background-position: right bottom; 
      }

      .btn-zoom {
        background: transparent !important;
      }
    }
  }

  input[hidden] + .input-notif + .input-group-addon {
    display: none;
  }
}

#{$lp-forms-components-readonly} {
  position: relative;
  display: inline-block;

  &:before {
    position: absolute;
    cursor: not-allowed;
    content: "";
    display: block !important;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 10;
    @include border-radius(3px);
  }
}

#{$lp-forms-components-error-exception} {
  &.ng-invalid:hover>span,
  >span:focus-within {
    &:before {
      display: none;
    }
  }
}


lp-number,
lp-currency,
lp-currency-v2 {
  input {
    text-align: right;
  }
}

lp-report,
lp-field-details,
lp-application-item-details,
lp-zoom {
  .panel-heading {
    position: relative;

    .title-buttons {
      position: absolute;
      right: 8px;
      top: 7px;
    }

    .btn {
      padding: 0.175rem 0.55rem;
    }
  }

  .criterias-zoom {
    label {
      min-height: 20px;
    }

    form + div {
      width: 100px;
    }
  }
}

/**************
* LABEL
**************/
.label-2-colonnes {
  lp-label-field {
    label {
      height: auto;
      font-size: 0.8em;
    }
  }
}

lp-label-field {
  vertical-align: middle;
  label {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    height: 42px;
  }
}

/**************
* ZOOMFIELD 
**************/
lp-zoom-field {      
  .btn-update {
    font-size: 1.75em;
    position: absolute;
    top: 0px;
    right: 43px;
    cursor: pointer;
    height: 100%;
    overflow: hidden;

    > span {      
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &:hover {
      color: $primary;
    }
  }

  .btn-scheduler {    
    right: 40px;
    position: absolute !important;
    top: 0;
  }

  lp-ui-popover-info {
    width: 100%;

    .form-control {
      width: 100% !important;
    }
  }

  .btn-zoom {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-search {
      font-size: 1.3em;
    }
  }
  

  .btn-right {
    .icon {
      font-size: 1.3em;
      display: block;
      margin-top: 4px;
    }
  }

  .equipment-zoom {
     .input-group input {
      padding-right: 110px !important;
    }
    .btn-update {
      right: 84px;
    }
  }
}

div.zoomField {
  z-index: 4;
  position: absolute;
  border-style: outset;
  background-color: $white;
  border: 1px solid lighten($dark, 27%);
  float: none;
  clear: both;
  width: 100%;
  overflow-y: auto;
  cursor: pointer;
  top: 37px;
  left: 0px;
  max-height: 200px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;

      span {
        display: block;
        padding-left: 12px;

        &:hover,
        &:focus,
        &.selected {
          color: $white;
          background-color: $primary;
        }
      }
    }
  }
}

/**************
* FUEL GAUGE
**************/
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid $lightdark-alt;
  height: 16px;
  width: 16px;
  @include border-radius(15px);
  background: $white;
  cursor: pointer;
  margin-top: -2px;
  @include box-shadow(1px 1px 1px $black, 0px 0px 1px lighten($black, 5%));
  /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  @include box-shadow(1px 1px 1px $black, 0px 0px 1px lighten($black, 5%));
  border: 1px solid $lightdark-alt;
  margin-top: 10px;
  height: 16px;
  width: 16px;
  @include border-radius(3px);
  background: $white;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  @include box-shadow(1px 1px 1px $black, 0px 0px 1px lighten($black, 5%));
  border: 1px solid $lightdark-alt;
  margin-top: 10px;
  height: 16px;
  width: 16px;
  @include border-radius(3px);
  background: $white;
  cursor: pointer;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

lp-fuel-gauge {

  input[type=range] {
    background: #e9ecef;
    border: 1px solid lighten($muted, 19%);
    @include border-radius(3px);
    height: 8px;
  }

  div {
    height: 38px;
    display: flex;
    align-items: center;
  }

  .labelResult {    
    white-space: nowrap;
    padding-left: 10px;
    padding-bottom: 3px;
  }
}


/**************
* IMAGE FIELD
**************/
lp-image-field {
  .file {
    max-width: 100%;
    max-height: 270px;

    &.zoomable {
      cursor: zoom-in;
    }
  }
}

/**************
* BASIC FIELD
**************/
lp-basic-file-field {
  .form-control-file {
    visibility: hidden;
  }
}

/**************
* DROP FILE FIELD
**************/
lp-drop-file {
      
    .uploadfilecontainer {

      .btnUpload {
        border: 1px solid lighten($muted, 19%);
        @include border-radius(4px);
      }
      

      &.dragOver {
        background: url("/assets/img/drop.png") center center no-repeat;
        background-color: lighten(lighten($muted, 19%), 15%);
        color: lighten(lighten($muted, 19%), 15%);
        font-size: 0;
        

        .btnUpload {
          min-height: 100px;

          .btn {
            display: none;
          }
        }
      }
    }
    
    
    .files-list {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 10px auto;
        background: $white;
        border: 1px dashed;
        @include border-radius(12px);
        padding: 5px;
        position: absolute;
        & .delete-file {
            background: transparent;
            border: none;
            cursor: pointer;
        }
    }
}

/**************
* DATETIME FIELD
**************/
/* date / datetime */
lp-date,
lp-date-month,
lp-date-time,
lp-date-time-range {
  .form-control {
    &[readonly] {
      cursor: pointer !important;
    }
  }

  .icon {
    font-size: 1.5em;
    margin-top: 2px;
    display: block;
  }
}

lp-time {
  span {
    position: relative;
    float: left;
    width: 100%;

    &:after {
      position: absolute;
      display: block;
      content: "";
      height: calc(100% - 2px);
      width: 45px;
      background: $white;
      right: 1px;
      top: 1px;
    }
  }
}

.owl-dt-dialog-container {
  margin: -1em -1.5em -1.5em;

}

owl-date-time-container.owl-dt-container {
  .owl-dt-calendar-table {
    .owl-dt-calendar-header {
      .owl-dt-weekdays {
        th {
          font-size: 1em;
        }
      }
    }

    .owl-dt-calendar-cell-content {
      @include border-radius(5px);
      font-size: 1em;
      padding-top: 4px;

      &.owl-dt-calendar-cell-selected {
        background: $dark;
      }
    }
  }

  .owl-dt-container-buttons {
    height: 35px !important;

    >button {
      overflow: hidden;

      span {
        position: relative;

        &:before {
          font: normal normal normal 1.5em/1 "vega-custom-font";
          content: "\e955";
        }
      }

      &:nth-child(2) {

        span:before {
          content: "\e96a";
        }
      }
    }
  }

  .owl-dt-control-button.owl-dt-control-arrow-button {
    width: 100%;

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.04);

      .owl-dt-control-button-content {
        background-color: transparent;
      }
    }
  }

  .owl-dt-container-buttons {
    height: auto;

    button {
      &:first-child {
        background: $lightgray2;
        color: $lightdark3;
      }

      &:last-child {
        background: $dark;
        color: $white;
      }

      span {
        padding: 10px 0;
      }
    }
  }
}

owl-date-time-multi-year-view {
  >button {
    width: 30px !important;
  }
}

/**************
* RADIOBOX FIELD
**************/
/* bouton radio */
.custom-radio {
  cursor: pointer;
  padding-left: 25px;
  position: relative;

  .custom-control-indicator {
    position: absolute;
    top: .3rem;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: lighten($light5, 3%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    @include border-radius(50px);
    border: 1px solid lighten($muted, 19%);
  }

  &:not(.disabled):hover {
    .custom-control-indicator {
      background-color: lighten($primary, 10%);
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      width: 25px;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
    }
  }

  input:checked+.custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    color: $white;
    background-color: $primary !important;
  }
}

/* FIX lp-radiolp-radio-box readonly */
lp-radio-box {
  .radio-readonly {    
    float: left;
    width: 100%;
  }
}

/**************
* SWITCHBOX FIELD
**************/
lp-switch-box, .dhx_wrap_section  {
  .switch-toggle.switch-candy, .dhx_cal_radio {
    height: 38px;
    border: 1px solid lighten($muted, 19%);
    background: lighten($light5, 3%);
    @include box-shadow(none);

    label {
      line-height: 34px;
      cursor: pointer;
      color: $black;
      text-shadow: none;
      border: 1px solid transparent;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:not(:checked):hover {
        border-color: $focusbtn;
        height: 100%;
        @include border-radius(4px);
      }
    }

    .disabled:before {
      background: rgba($white, 0.5);
      cursor: not-allowed;
    }

    input:checked+label {
      color: $white;
      cursor: default;

      &:hover {
        border-color: transparent;
      }
    }

    a {
      background: none;
      border: 0;
      @include box-shadow(none);
    }

    input:checked~a {
      background: $primary;
      @include box-shadow(none);
    }
  }
}

/**************
* CHECKBOX FIELD
**************/
lp-checkbox input {
  height: 36px;
}

.checkbox {
  padding-left: 20px;

  label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    cursor: pointer;
    display: block;    
    font-size: 0.9em;

    &:before {
      @include transition(all, 0.3s, ease-in-out);
      background-color: transparent;
      @include border-radius(3px);
      border: 2px solid $muted;
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      top: 7px;
      margin-left: -20px;
      position: absolute;
      width: 17px;
      outline: none !important;
    }

    &:not(.readonly) {   
      &:hover {
        &:before {
          background-color: lighten($primary, 10%);
          border-color: lighten($primary, 10%);
        }
      }
    }

    &.readonly, &.readonly * {
      cursor: not-allowed;
    }
  }

  &.checked {
    label {
      &:before {
        background-color: $primary;
        border-color: $primary;
      }

      &:after {
        color: $light;
        display: inline-block;
        font-size: 11px;
        height: 16px;
        left: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        position: absolute;
        top: 5px;
        width: 16px;
        content: "\f00c";
        font-family: 'FontAwesome';
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }
}

/**************
* textarea
**************/
lp-textarea-field > span textarea.form-control {
  height: auto;
}


/**************
* print report
**************/
lp-print-report {
  bottom: 25px;
  position: fixed;
  right: 45px;

  button {
    background: transparent;
    border: 0;
    font-size: 3em;
    line-height: 1em;
    height: 31px;
    cursor: pointer;      
    display: inline-block;
    vertical-align: middle;
  }
}

/**************
* mail report
**************/
.mail-sidebar-item {
  bottom: 25px;
  position: fixed;
  right: 90px;
  background: transparent;
  border: 0;
  font-size: 3em;
  line-height: 1em;
  height: 31px;
  cursor: pointer;      
  display: inline-block;
  vertical-align: middle;
}


/* =============
  LP COMON TOOLS
============= */
.lp-common-tools {
  position: fixed;
  top: 121px;
  right: 0;
  z-index: 1038;
  
  @media screen and (max-width: 767px) {
    right: 0px !important;    
  }

  .available {
    color: $red !important;

    &:hover {
      @include transition(all, 0.1s, linear);
      background-color: lighten($red, 35) !important;
    }
  }

  .disabled {
    color: lighten($dark, 35) !important;

    &:hover {
      i {
        cursor: not-allowed !important;
      }
    }
  }

  .sidebar-item:not(.no-float),
  .sidebar-more-item {
    border: 1px solid darken($white, 28%);
    border-top-width: 0;
    display: block;
    float: right;
    width: 44px;
    height: 41px;
    padding: 0px;
    background-color: $white;
    border-right: 0;
    cursor: pointer;

    &:not(.hide) {
      > .content-more {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:hover {
      @include transition(all, 0.1s, linear);
      background-color: lighten($dark, 65);
      color: $primary;
    }

    i {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 0;
      cursor: pointer;

      &.icon {
        font-size: 30px;
        vertical-align: middle;
        line-height: 28px;
      }

      &[class^="vega-"], &[class*=" vega-"] {
        font-size: 2.3em;
      }

      &.far, &.fas {
        font-size: 1.6em;
      }

      .mat-badge-content {
        top: 0;
        right: 0;
        background-color: $primary;
      }
    }

    &.last-sidebar-item {
      border-bottom: 0;
    }

    &.sidebar-more-item {
      border: 0;

      i {        
        .mat-badge-content {
          right: 3px;
        }
      }
    }
  }



  .submit-form-buttons {
    bottom: 25px;
    position: fixed;
    right: calc(50% + 45px);

    button {
      background: transparent;
      border: 0;
      font-size: 3em;
      line-height: 1em;
      height: 31px;
      cursor: pointer;      
      display: inline-block;
      vertical-align: middle;
        
      &.delete-btn {
        font-size: 2.9em;
        margin-top: -3px;
      }

      &.save-btn {
        font-size:2.4em;
      
        &.disabled {
          .vega-forbidden {            
            position: absolute;
            left: 6px;
            color: #f00;
          }
        }
      }
    }


    *:hover {
      color: $primary;
    }
  }

  .more-item {
    width: calc(44px * 3 - 1px);
    height: 42px;
    position: relative;
    @include transition(all, 0.2s, linear);
    display: block !important;
    overflow: hidden; //border-top: 1px solid $lightdark7;
    float: right;

    &.item-actions {
      margin-top: -1px;

      > .content-more {
        border-bottom-left-radius: 4px;
        
        > a {
          border-bottom-left-radius: 4px;
        }
      }
    }

    &.hide {
      width: 44px !important;
      @include transition(all, 0.2s, linear);
    }

    .content-more {
      position: absolute;
      left: 0;
      top: 0px;
      border: 1px solid darken($white, 28%);
      background-color: $white;
      padding: 0;
      display: table;
      width: calc(44px * 4 + 2px);

      i {
        vertical-align: middle;
      }

      >a {
        display: table-cell;
        height: 38px;
        line-height: 38px;
        padding: 0;
        width: 44px;
        text-align: center;

        &:hover {
          @include transition(all, 0.1s, linear);
          background-color: lighten($dark, 65);
        }
      }

      >div {
        display: table-cell;
        width: calc(44px * 3);
        vertical-align: middle;

        >a {
          display: block;
          float: left;
          text-align: center;
          width: 44px;
          height: 38px;
          line-height: 38px;

          &:hover {
            @include transition(all, 0.1s, linear);
            background-color: lighten($dark, 65);
          }

          &.traces {
            position: relative;

            .ti-line-dotted {
              position: absolute;
              top: 17px;
              left: 10px;
            }
          }
        }
      }
    }
  }

  &.cols-2 {
    right: calc(50% - 17px);

    .accordion-close-btn {
      border-top: 1px solid  darken($white, 28%);
      border-top-left-radius: 4px;
    }

    .no-float {
      &.save-btn {
        right: calc(50% + 39px);
      }

      &.delete-btn {
        right: calc(50% + 89px);
        bottom: 21px;
      }

      &:hover {
        color: $primary;
      }
    }
  }
}

/* FIX Pricings */
.col-lg-3.label-2-colonnes {
  .lp-common-tools {
    left: calc(24% - 5px);
    right: auto;

    .sidebar-item,
    .more-item {
      display: none !important;

      &.duplicate-btn {
        display: block !important;
      }
    }
  }
}


/**************
* PDF VIEWER
**************/
lp-pdf-viewer {
  iframe {
    height: calc(100vh - 222px);
  }
}

/**************
* ZOOM IMAGE / PDF / TXT
**************/

lp-ui-zoom-pdf lp-pdf-viewer {
  width: 80%;
}

lp-ui-zoom-pdf, 
lp-ui-zoom-txt, 
lp-ui-zoom-image {
  white-space: pre-line;
  div {
    position: fixed;
    opacity: 0;
    @include vertical-align-middle();
    z-index: 10000;
    background: $bg-overlay;
    width: 0;
    height: 0;
    top: 0;
    left: 0;

    &.show {
      opacity: 1;
      width: 100%;
      height: 100%;
      cursor: zoom-out;
    }

    &:not(.show) {
      @include transition(opacity, 0.5s, ease-in-out);
      @include transition(width, 100ms, ease-in-out);
      @include transition(height, 100ms, ease-in-out);
      transition-delay: 0.5s, 0.5s, 0s;
      transition-property: width, height, opacity;
    }

    img {
      max-width: 90%;
      max-height: 90%;
      background: $white;
    }
  }
}


/**************
* Préférences
**************/
lp-modal-preferences {
  .col-right {
    .btn-i {
      width: 38px;
    }
  }
}

lp-active {
  top: 162px;
    z-index: 1039;
    text-align: center;
    border-bottom: 1px solid #b8b8b8;
    border: 1px solid #b8b8b8;
    border-top-width: 0;
    display: block;
    float: right;
    width: 44px;
    height: 41px;
    padding: 0px;
    background-color: #ffffff;
    border-right: 0;
    cursor: pointer;

    label {
      position: absolute;
      width: 22px;
      height: 22px;
      top: 50px;
      right: 11px;
      background-color: #ff5b5b;
      padding: 0;
      margin: 0;
      border-radius: 100px;
      border: 1px solid $lightdark-alt;
      cursor: pointer;
  
      &.checked {
        background-color: rgb(189, 236, 134);
      }
  
      input {
        display: none;
        float: right;
        position: relative;
      }
    }
}

/**************
* Préférences
**************/
.popover {
  font-family: inherit;
  @include border-radius(3px);
  .popover-header {
    background-color: transparent;
    color: $custom;
    font-weight: 600;
    margin: 0;
  }
}


lp-chips {
  .checkbox {
    display: block !important;
    float: left;
    width: auto;      
    border-radius: 16px;
    border: 1px solid $light; 
    margin: 4px;
    padding: 0;
    background-color: rgba($primary, 0.1);

    label {
      font-size: inherit;
      padding: 0 30px;
      line-height: 26px;
      margin:0;

      &:before, &:after {
        display: none !important;
      }
    }

    &:hover {
      background-color: $light;
      label:after {
        display: block !important;
        color: $muted;
        font-size: 18px;
        right: 6px;
        position: absolute;
        top: 0px;
        content: "\f057";
        font-family: "FontAwesome";
        cursor: pointer;
        color: $danger;
        left: inherit;
      }
    }
  }

  .not-selected {
    .checkbox {
      background-color: $white;
      &:hover {
        label:after {
          content: "\f00c";
          color: $success;
        }
      }
    }
  }  
}

lp-editor-field {
  $padding-codemirror-fixed: 20px;
  .codemirror-container {
    position: relative;

    &.fixed {
      position: fixed;
      top: 0px;
      left: 50px;
      width: calc(100vw - 50px);
      height: 100vh;
      z-index: 1500;
      padding: $padding-codemirror-fixed;
      background-color: rgba(0, 0, 0, 0.5);

      > i {
        top: calc(5px + $padding-codemirror-fixed);
        right: calc(25px + $padding-codemirror-fixed);
      }

      ngx-codemirror {
        width: 100%;
        height: 100%;

        > .CodeMirror {
          height: 100%;
        }
      }
    }

    > i {
      font-size: 2em;
      top: 5px;
      right: 25px;
      position: absolute;
      z-index: 1;
      cursor: pointer;
    }
  }

}

lp-zoom-chooser {
  select {
    height: 32px;
    padding: 0 10px !important;
  }
}


lp-switcher {
  .switcher-container {    
    height: 32px;
    border-radius: 15px;
    //border: 1px solid $muted;
    padding: 2px;
    background: #f1f1f1;

    .switcher-item {
      border-radius: 15px;
      transition: background-color 0.3s ;      
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #dedede;
        cursor: pointer;
      }

      &.selected {
        background-color: #0081822b;
      }
    }
  }
}


.panel {
  .panel-collapse { 
      display: block;   
      max-height: 0;
      overflow: hidden;
  }

  .panel-heading,
  .panel-collapse {
      float: left;
      width: 100%;
  }
  
  &.open {
      .panel-collapse {
          max-height: 50000px;
          overflow: visible !important;
      }        
  }
}

.notifications {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  min-width: 400px;
  max-width: 400px;
  margin-right: 5px;

}

.toast {
  padding: 15px 0;
  border-radius:10px;
  width: 100%;
  top: 0px;
  position:relative;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);
  display: block !important;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(5px);
  margin-top: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-top-left-radius:10px;
    border-bottom-left-radius: 10px;
  }   

  &.toast--success {
    .toast__icon{
      background-color:$success;
    }
    &:before{
      background-color:$success;
    }
  }


  &.toast--info {
    .toast__icon{
      background-color:$info;
    }
    &:before{
      background-color:$info;
    }
  }

  &.toast--warning {
    .toast__icon{
      background-color:$scheduler-orange;
    }
    &:before{
      background-color:$scheduler-orange;
    }
  }

  &.toast--error {
    .toast__icon{
      background-color:$danger;
    }
    &:before{
      background-color:$danger;
    }
  }

  .toast__icon{
    position:absolute;
    top:50%;
    left:22px;
    transform:translateY(-50%);
    width:14px;
    height:14px;
    padding: 7px;
    border-radius:50%;
    display:inline-block;
    box-sizing: content-box;
    
    .toast__svg{
      fill:#fff;
      margin-top: -10px;
    }
  }

  .toast__content{
    padding-left:70px;
    padding-right:60px;
  
    .toast__type {
      color: #3e3e3e;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 0;
    }
    
    .toast__message {
        margin-top: 0;
        margin-bottom: 0;
        color: #878787;
    }
    
  }
  
  .toast__close {
      position: absolute;
      right: 22px;
      top:calc(50% - 7px);
      width: 14px;
      cursor:pointer;
      height: 14px;
      fill:#878787;
      transform: translateY(-50%);
  }
}

lp-modal-addcompany {
  lp-label-field {
    label {
      height: auto;
      font-size: 0.8em;
    }
  }

  button {
    background: transparent;
    border: 0;
    font-size: 3em;
    line-height: 1em;
  }
}


lp-news {
  .gridster-item-inner {
    position: static;
  }  
}

lp-modal-dashboard {
  .ck-content {
    min-height: 250px;
  }
}

ngx-datatable.themes datatable-row-wrapper datatable-body-row datatable-body-cell .datatable-body-cell-label {
  align-items: center;
  padding: 0 10px;
}