
@mixin mat-progress-bar-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
  
    .mat-progress-bar-background {
      fill: mat.get-color-from-palette($primary, lighter);
    }
  
    .mat-progress-bar-buffer {
      background-color: mat.get-color-from-palette($primary, lighter);
    }
  
    .mat-progress-bar-fill::after {
      background-color: mat.get-color-from-palette($primary);
    }
  
    .mat-progress-bar.mat-accent {
      .mat-progress-bar-background {
        fill: mat.get-color-from-palette($accent, lighter);
      }
  
      .mat-progress-bar-buffer {
        background-color: mat.get-color-from-palette($accent, lighter);
      }
  
      .mat-progress-bar-fill::after {
        background-color: mat.get-color-from-palette($accent);
      }
    }
  
    .mat-progress-bar.mat-warn {
      .mat-progress-bar-background {
        fill: mat.get-color-from-palette($warn, lighter);
      }
  
      .mat-progress-bar-buffer {
        background-color: mat.get-color-from-palette($warn, lighter);
      }
  
      .mat-progress-bar-fill::after {
        background-color: mat.get-color-from-palette($warn);
      }
    }
  }
  
  .mat-progress-bar-fill:after {
    background-color: $primary;
  }
  
  .mat-progress-bar-background {
    fill: lighten($primary, 15);
  }
  
  .mat-progress-bar-buffer {
    background-color: lighten($primary, 15);
  }