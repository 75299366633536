/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
mat.$theme-ignore-duplication-warnings: true;

.row>div {  margin-bottom: 15px;}
.panel-heading {  font-weight: bold;}


/* accordion-demo only */
.card.customClass,.card.customClass .card-header,.panel.customClass {  background-color: #5bc0de;  color: #fff;}
.panel.customClass .panel-body {  background-color: #337aa7;}
/* end of accordion-demo only */

body{
     background-color: #EDEDED; 
}

// Police principale
@import "assets/fonts/roboto/roboto";
@import "sass/icons";
@import "sass/variables";

@import "sass/material_parts/button";
@import "sass/material_parts/snackbar";
@import "sass/material_parts/slidertoggle";
@import "sass/material_parts/modal";
@import "sass/material_parts/progressbar";

@import "sass/mixins";
@import "sass/base64";
@import "sass/global";
@import "sass/core";
@import "sass/components";
@import "sass/lpcomponents";
@import "sass/scheduler";
@import "sass/menu";
@import "sass/modal";
@import "sass/tables";
@import "sass/loader";
@import "sass/alert";
@import "sass/carousel";
@import "sass/accordion";
@import "sass/userevent";
@import "sass/memo";
@import "sass/type_widget";
@import "sass/_formcard";

@import "sass/print";

/* spécifique au écrans */
@import "sass/screens/rootform";
@import "sass/screens/ft-pricingdetail";
@import "sass/screens/home";
@import "sass/screens/login";
@import "sass/screens/inspection";

/* transition pendant le changement de thème */
@import "sass/transition";

/* json-editor */
@import "~jsoneditor/dist/jsoneditor.min.css";



/*Thèmes supplémentaires */
@import "sass/themes/dark/index";
@import "sass/themes/light/index";
@import "sass/themes/stime/index";
@import "sass/themes/nes/index";
//@import "sass/themes/u/index";
@import "sass/themes/irium2k23/index";
@import "sass/themes/iMobCheckPlus/index";
@import "sass/themes/galec/index";


/* responsiv */
@import "sass/responsive";
@import "./app/ui/lp-card/gridster.scss";

/* DEBUG */
pre {    
    max-width: 1760px;
    overflow: auto;
}

 // En cas d'utilisation d'un nouveau composant de material, décommenter cette partie pour avoir le CSS.
@import 'node_modules/@angular/material/theming';
@include mat.core();
$vega-material-primary: mat.define-palette(mat.$cyan-palette,900);
$vega-material-accent:  mat.define-palette(mat.$cyan-palette,900);
$vega-material-warn:    mat.define-palette(mat.$cyan-palette,900);

$vega-material-theme: mat.define-light-theme((
 color: (
   primary: $vega-material-primary,
   accent: $vega-material-accent,
   warning: $vega-material-warn
 ),
 density: 0,
));
@include mat.all-component-themes($vega-material-theme);



@import 'node_modules/codemirror/lib/codemirror';
@import 'node_modules/codemirror/theme/neat';

::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.2);
background-color: #F5F5F5;
}

::-webkit-scrollbar
{
width: 12px;
background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(75, 75, 75, 0.1);
background-color: #a0a0a0;
}
