.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay {
	opacity: 0
}

.mat-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay {
	opacity: .04
}

@media(hover: none) {
	.mat-button:hover .mat-button-focus-overlay,
	.mat-stroked-button:hover .mat-button-focus-overlay {
		opacity: 0
	}
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
	box-sizing: border-box;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	outline: none;
	border: none;
	-webkit-tap-highlight-color: transparent;
	display: inline-block;
	white-space: nowrap;
	text-decoration: none;
	vertical-align: baseline;
	text-align: center;
	margin: 0;
	min-width: 64px;
	line-height: 36px;
	padding: 0 16px;
	border-radius: 4px;
	overflow: visible
}

.mat-button::-moz-focus-inner,
.mat-icon-button::-moz-focus-inner,
.mat-stroked-button::-moz-focus-inner,
.mat-flat-button::-moz-focus-inner {
	border: 0
}

.mat-button[disabled],
.mat-icon-button[disabled],
.mat-stroked-button[disabled],
.mat-flat-button[disabled] {
	cursor: default
}

.mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-button.cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-program-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-program-focused .mat-button-focus-overlay {
	opacity: .12
}

.mat-button::-moz-focus-inner,
.mat-icon-button::-moz-focus-inner,
.mat-stroked-button::-moz-focus-inner,
.mat-flat-button::-moz-focus-inner {
	border: 0
}

.mat-raised-button {
	box-sizing: border-box;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	outline: none;
	border: none;
	-webkit-tap-highlight-color: transparent;
	display: inline-block;
	white-space: nowrap;
	text-decoration: none;
	vertical-align: baseline;
	text-align: center;
	margin: 0;
	min-width: 64px;
	line-height: 36px;
	padding: 0 16px;
	border-radius: 4px;
	overflow: visible;
	transform: translate3d(0, 0, 0);
	transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)
}

.mat-raised-button::-moz-focus-inner {
	border: 0
}

.mat-raised-button[disabled] {
	cursor: default
}

.mat-raised-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-raised-button.cdk-program-focused .mat-button-focus-overlay {
	opacity: .12
}

.mat-raised-button::-moz-focus-inner {
	border: 0
}

._mat-animation-noopable.mat-raised-button {
	transition: none;
	animation: none
}

.mat-stroked-button {
	border: 1px solid currentColor;
	padding: 0 15px;
	line-height: 34px
}

.mat-stroked-button .mat-button-ripple.mat-ripple,
.mat-stroked-button .mat-button-focus-overlay {
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px
}

.mat-fab {
	box-sizing: border-box;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	outline: none;
	border: none;
	-webkit-tap-highlight-color: transparent;
	display: inline-block;
	white-space: nowrap;
	text-decoration: none;
	vertical-align: baseline;
	text-align: center;
	margin: 0;
	min-width: 64px;
	line-height: 36px;
	padding: 0 16px;
	border-radius: 4px;
	overflow: visible;
	transform: translate3d(0, 0, 0);
	transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	min-width: 0;
	border-radius: 50%;
	width: 56px;
	height: 56px;
	padding: 0;
	flex-shrink: 0
}

.mat-fab::-moz-focus-inner {
	border: 0
}

.mat-fab[disabled] {
	cursor: default
}

.mat-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-fab.cdk-program-focused .mat-button-focus-overlay {
	opacity: .12
}

.mat-fab::-moz-focus-inner {
	border: 0
}

._mat-animation-noopable.mat-fab {
	transition: none;
	animation: none
}

.mat-fab .mat-button-wrapper {
	padding: 16px 0;
	display: inline-block;
	line-height: 24px
}

.mat-mini-fab {
	box-sizing: border-box;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	outline: none;
	border: none;
	-webkit-tap-highlight-color: transparent;
	display: inline-block;
	white-space: nowrap;
	text-decoration: none;
	vertical-align: baseline;
	text-align: center;
	margin: 0;
	min-width: 64px;
	line-height: 36px;
	padding: 0 16px;
	border-radius: 4px;
	overflow: visible;
	transform: translate3d(0, 0, 0);
	transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	min-width: 0;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 0;
	flex-shrink: 0
}

.mat-mini-fab::-moz-focus-inner {
	border: 0
}

.mat-mini-fab[disabled] {
	cursor: default
}

.mat-mini-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
	opacity: .12
}

.mat-mini-fab::-moz-focus-inner {
	border: 0
}

._mat-animation-noopable.mat-mini-fab {
	transition: none;
	animation: none
}

.mat-mini-fab .mat-button-wrapper {
	padding: 8px 0;
	display: inline-block;
	line-height: 24px
}

.mat-icon-button {
	padding: 0;
	min-width: 0;
	width: 40px;
	height: 40px;
	flex-shrink: 0;
	line-height: 40px;
	border-radius: 50%
}

.mat-icon-button i,
.mat-icon-button .mat-icon {
	line-height: 24px
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	pointer-events: none;
	border-radius: inherit
}

.mat-button-ripple.mat-ripple:not(:empty) {
	transform: translateZ(0)
}

.mat-button-focus-overlay {
	opacity: 0;
	transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1), background-color 200ms cubic-bezier(0.35, 0, 0.25, 1)
}

._mat-animation-noopable .mat-button-focus-overlay {
	transition: none
}

.cdk-high-contrast-active .mat-button-focus-overlay {
	background-color: #fff
}

.cdk-high-contrast-black-on-white .mat-button-focus-overlay {
	background-color: #000
}

.mat-button-ripple-round {
	border-radius: 50%;
	z-index: 1
}

.mat-button .mat-button-wrapper>*,
.mat-flat-button .mat-button-wrapper>*,
.mat-stroked-button .mat-button-wrapper>*,
.mat-raised-button .mat-button-wrapper>*,
.mat-icon-button .mat-button-wrapper>*,
.mat-fab .mat-button-wrapper>*,
.mat-mini-fab .mat-button-wrapper>* {
	vertical-align: middle
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
	display: block;
	font-size: inherit;
	width: 2.5em;
	height: 2.5em
}

.cdk-high-contrast-active .mat-button,
.cdk-high-contrast-active .mat-flat-button,
.cdk-high-contrast-active .mat-raised-button,
.cdk-high-contrast-active .mat-icon-button,
.cdk-high-contrast-active .mat-fab,
.cdk-high-contrast-active .mat-mini-fab {
	outline: solid 1px
}