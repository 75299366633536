.mainzoomBackdropClass {
    display: none;

    + .cdk-global-overlay-wrapper {       
        z-index: 1 !important;     
        justify-content: end !important;

        .cdk-overlay-pane {
            width: calc(100vw - 70px) !important;
            max-width: calc(100vw - 70px) !important;
            margin-top: 103px;
            max-height: calc(100vh - 103px);

            mat-dialog-container {                
                border-radius: 0;
                box-shadow: none; 
                background: transparent;
                backdrop-filter: none;

                lp-zoom-chooser {
                    select {
                        border-radius: $border-radius;
                    }
                }

                lp-button-new {
                    a {
                        border-radius: $border-radius;
                    }
                }


                .panel-heading {     
                    position: fixed;
                    top: 55px;    
                    right: 0;                         
                    width: 50%;
                    cursor: default;

                    h5 {
                        display: none;                        
                    }
                }

                .modal-body {
                    border-top: 0;
                    padding: 0;

                    .criterias-zoom {                        
                        padding: 0 8px 8px;
                        border-bottom: 1px solid #ccc;
                        margin: 0 !important;
                        
                        label {
                            margin-left: 15px;
                        }

                        input {
                            border-radius: $border-radius;
                        }

                        .input-group-btn  {
                            width: 36px;

                            button {
                                border-top-right-radius: $border-radius;
                                border-bottom-right-radius: $border-radius;
                                padding-right: 5px !important;
                            }
                        }

                        > form + div {
                            a {
                                width: 38px !important;
                                border-radius: $border-radius;                                
                                padding-left: 0;
                                padding-right: 0;
                                text-align: center;
                            }
                        }
                    }

                    ngx-datatable {
                        border: 0 !important;
                        background-color: transparent;  
                        padding: 0 8px;

                        lp-cell,
                        datatable-header-cell {
                            padding: 2px 5px !important;
                            box-sizing: border-box;
                        }

                        datatable-header {
                            background-color: transparent!important;
                            border: 0 !important;                            
                            padding: 0px 10px;

                            .datatable-header-cell-wrapper {
                                display: inline-block;
                                width: auto;
                                margin-right: 5px;
                            }

                            .sort-btn {
                                position: static;
                            }

                            * {
                                border: 0 !important;
                                color: #000;
                            }
                        }

                        datatable-body {  
                            overflow-x: visible;
                            
                            datatable-body-row, 
                            .empty-row {
                                height: auto !important;
                                min-height: 40px !important;
                                margin: 5px 0 !important;                            
                                box-shadow: 0px 0px 10px #bdbdbd;
                                border-radius: 10px;
                                background-color: #fff;                          

                                .datatable-row-group {
                                    background-color: transparent;      

                                    &:hover {
                                        background-color: #00818226;
                                    }

                                    &.datatable-row-center {
                                        border-radius: 10px;
                                        padding: 0px 10px;
                                    }

                                    datatable-body-cell {
                                        border: 0;
                                        height: auto !important;
                                        min-height: 40px !important;
                                        line-height: 1.5em;

                                        [class^="vega-"], [class*=" vega-"] {
                                            height: auto;
                                            font-size: 1.2em;
                                            vertical-align: middle;
                                            padding-top: 1px;
                                        }
                                    }
                                }
                            }
                        }

                        datatable-footer {
                            border-radius: 10px;
                            margin-top: 5px;

                            a {
                                border-radius: 25px;

                                &.btn-export {
                                    height: 26px;
                                    padding-top: 0;
                                    padding-bottom: 0;
                                    line-height: 26px;
                                }
                            }

                            select {                                
                                border-radius: 25px;
                                height: 28px;
                                padding: 0 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mat-mdc-dialog-surface {
    background-color: transparent !important;
    box-shadow: none !important;
}