

// Pour centrer le bloc verticalement au 
@mixin vertical-align-middle() {
    display: flex;
    justify-content: center;
    align-items: center;   
}

// angles arrondis + rétrocompatibilité
@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

// ombres + rétrocompatibilité
@mixin box-shadow($shadow...) {
	box-shadow: $shadow;
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
}

// utilisé lors d'animations CSS + rétrocompatibilité
@mixin transition($property, $duration, $easing: linear) {
	transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
}

// colonne de droite dans le planning
@mixin scheduler-box-setting() { 
    position: absolute;
    padding: 10px;
    z-index: 3;
    width: 450px;
    top: 0;
    right: 0;
    height: calc(100vh - 103px);
    background-color: $white;
    overflow-y: scroll;
    overflow-x: hidden;
    @include box-shadow(0px 0px 18px 0px rgba(140, 140, 140, 1));
}

// Affichage des liste en bas à droite (User History / Report Spooler)
@mixin list-bottom-right($width) {
  position: absolute;
  left: calc(100%);
  bottom: 0;
  animation: listSpoolerOpened 0.5s;
  display: flex;
  flex-direction: column-reverse;
  width: $width;

  > div {
    position: relative;
    
    .list {
      width: $width;
      overflow: auto;
      max-height: calc(100vh - 58px);
    }
  }
}

// Affichage des items dans liste en bas à droite (User History / Report Spooler)
@mixin list-bottom-right-item {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 0px 14px 0px rgba(108,108,108,1);
    margin-left: 10px;
    width: calc(100% - 20px);
} 