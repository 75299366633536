lp-subgroup {
    .lp-subgroup-title {
      font-weight: 500;
      font-size: 1.2em;
    }
}


.panel-heading {
  min-height: 46px;
}

 .panel-body {
    .pre-foreach {
      text-indent: -140px;
      margin: 0;
      border-bottom-width: 0;
      border-top-width: 0;
      padding: 10px;
      margin-top: -60px;
  
      &:first-child() {
        border-top-width: 1px;
      }
  
      &:last-child() {
        border-bottom-width: 1px;
        margin-bottom: 20px;
      }
    }
  }