/* =============
   Menu de lécran (1ere ligne- couleur VGEA) : recehrche générale + menu utilisateurs
============= */
$height-menu: 50px;
#menu {
    background: $primary;

    /* Toolbar de gauche */
    .left-menu {
      position: fixed;
      width: 50px;
      height: 100%;
      background: $white;
      left: 0px;
      top: 0;
      @include box-shadow(-2px 0px 15px 6px rgba($black, 0.4));
      z-index: 1080;

      lp-outbar {        
        ul {
          padding: 0;
          margin-top: 0px;
      
          &.top {
            max-height: calc(100% - 55px);
            width: calc(100% + 20px);
            overflow-y: auto;
            overflow-x: hidden;
      
            li:first-child {
              margin-top: 5px;
            }
          }
      
          li {
            width: 50px;
            text-align: center;
      
            a {
              display: inline-block;
              width: 100%;
              height: 40px;
              overflow: hidden;
              color: $dark;
              font-size: 2.75em;

              i[class^="vega-"], i[class*=" vega-"] {                
                vertical-align: top;
                margin-top: 3px;
                display: block;
              }

              i.far, i.fas {      
                font-size: 0.7em;
                vertical-align: top;
                margin-top: 7px;
                display: block;
              }     
    
      
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }

      .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 5px;
        background: $white;  
        width: 100%;          
      }
      
      lp-user-history,
      lp-report-spooler {
        > a {
          display: inline-block;
          width: 100%;
          height: 40px;
          overflow: hidden;
          color: $dark;
          font-size: 2.75em;

          i[class^="vega-"], i[class*=" vega-"] {                
            vertical-align: top;
            margin-top: 3px;
            display: block;
          }

          i.far, i.fas {      
            font-size: 0.7em;
            vertical-align: top;
            margin-top: 7px;
            display: block;
          }     

  
          &:hover {
            color: $primary;
          }

        }
      }
    }
    /* Fin toolbar de gauche */

    /* Champs de recherche globale */
    .global-search {
      height: 30px;
      display: inline-block;
      position: relative;
    
      > * {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        background: $white;
        border: 1px solid darken($white, 13%);
        border-left: 0;
        margin-top: 0px;
      }
    
      span {
        padding: 0 10px 0 0;
        width: 500px;
        max-width: calc(100vw - 600px);
        border-left: 1px solid $muted;
        position: relative;
        vertical-align: bottom;
    
        &:before {
          position: absolute;
          top: 3px;
          left: 2px;
          color: $muted;
          font-size: 1.75em;
        }

        &.loader-active {
          &:before {
            display: none;
          }
        }
    
        input {
          padding-left: 30px;
          display: block;
          width: 100%;
          height: 100%;
          border: 0;
          font-family: "roboto";
        }
    
        .loader-search {
          position: absolute;
          top: 3px;
          left: 4px;
          background: transparent;
          height: 20px;
          width: 20px;
          border: 0;
          @include border-radius(0);
          background-size: 100%;
        }
      }
      
    
      .nosearch {        
        position: absolute;
        left: 0;
        z-index: 1000;
        top: calc(100% + 4px);        
        line-height: 30px;
        height: 40px;        
        width: calc(100% - 55px);
      }
    
      select {
        padding-left: 5px;
        margin-top: 3px;    
        max-width: calc(100vw - 700px);
        min-width: 75px;
      }
    
      button {
        width: 45px;
        text-align: center;
        cursor: pointer;
        background: darken($white, 13%);
        @include border-radius(0 4px 4px 0);
        border-left: 0;
        margin-top: 2px;
        font-size: 1.5em;
    
        &:hover {
          color: $primary;
        }
    
        &.newScope {
          background: none;
          border: 0;
          font-size: 1.95em;
          margin-left: 10px;
          color: $white;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
    /* Fin de champs de recherche globale */    
    
    /* Partie gauche */
    .navbar-custom {  

        > ul {
          position: relative;
    
          >li {
            vertical-align: baseline;
            min-height: 63px;
            position: static;
    
            &>a {
              padding: 5px 0 5px 5px;
              margin: 15px 0 5px 0px;
              color: $white !important;
    
              >i.fa,
              >i[class^="dripicons-"],
              >i[class^="ti-"] {
                vertical-align: inherit;
    
                &+span {
                  margin-left: 10px;
                }
              }
    
              >i {
                width: 11px;
    
                &.fa-times {
                  font-size: 1.1em;
                }
              }
    
              i.fa+span,
              i[class^="dripicons-"]+span,
              i[class^="ti-"]+span {
                vertical-align: middle;
                display: inline-block;
                overflow: hidden;
                max-width: 0;
                margin-left: 10px;
                @include transition(max-width, .5s, ease);
                white-space: nowrap;
              }
            }
    
            &:hover,
            &.show {
              a {
    
                i.fa+span,
                i[class^="dripicons-"]+span,
                i[class^="ti-"]+span {
                  max-width: 150px;
                  @include transition(max-width, .5s, ease);
                  padding-right: 10px;
                  margin-right: 0px;
                }
              }
            }
    
            ul {
              position: absolute;
              width: 600px;
              @include border-radius(0);
              padding: 5px 0;
    
              li {
                display: block;
                float: left;
                width: 50%;
    
                a {
                  color: $dark;
                  padding: 6px 1rem;
                  display: table;
                  position: relative;
    
                  &:before {
                    position: absolute;
                    width: 230px;
                    height: 1px;
                    content: "";
                    bottom: 0;
                    left: 40px;
                    background-color: lighten($dark, 65%);
                  }
    
                  &:after {
                    position: absolute;
                    width: 0;
                    height: 1px;
                    content: "";
                    bottom: 0;
                    left: 40px;
                    background-color: $primary;
                  }
    
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 0;
                    display: table-cell;
                    text-align: left;
                  }
    
                  i {
                    width: 25px;
                    display: table-cell;
                  }
                }
    
                &:hover {
                  a {
                    color: $primary !important;
                    background: transparent;
    
                    &:after {
                      width: 230px;
                      @include transition(width, .5s, ease);
                    }
                  }
                }
              }
            }
          }
        }
    }
    /* Fin partie gauche */

    /* Actions sur la partie droite */
    .topbar-right-menu {
        .btn-group {
            .nav-link {                
                display: flex;
                align-items: center;
                height: 53px;
                color: rgba(255, 255, 255, 0.6);                
                padding: 0 12px 0px;
                background: transparent;
                border: 0;
            
                &:hover {
                    i {
                    color: $white;
                    }
                }
            
                img, .noimg {
                    height: 32px;
                    width: 32px;
                }                
            
                .noimg {
                  background: $noUserImg;
                  background-size: contain;
                  background-position: center;
                  display: inline-block;
                  filter: brightness(0) invert(1);
                  vertical-align: middle;
                  margin-right: 5px;
                  background-size: contain !important;

                  &.noActionImg {
                    background: $noActionImg;
                  }
                }

                .fa-caret-up {
                    display: none;
                }
            }
      
            &.open {
                .fa-caret-down {
                display: none;
                }
        
                .fa-caret-up {
                display: inline-block;
                }
            }
            
            .dropdown-menu-right {
                width: 300px;
                @include border-radius(0);
                z-index: 1039;

                .currentLocation {
                    font-size: 0.8em;
                    white-space: nowrap;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                }
            
                li {
                    width: 100%;
            
                    .col-photo {
                        padding-top: 10px;
                        text-align: center;
                        padding-right: 0;
                
                        &+div {
                            padding-top: 25px;
                            padding-left: 0;
                            line-height: 1.5em;
                
                            span {
                            font-weight: bold;
                            }
                        }
                    }
            
                    .img-user-dropdown,
                    .noimg-user-dropdown {
                        width: 70px;
                        height: 70px;
                    }
            
                    .noimg-user-dropdown {
                      background: $noUserImg;
                      background-size: contain;
                      background-position: center;
                      display: inline-block;   
                      margin-top: 10px;
                      margin-right: 5px;
                      margin-left: 10px;
                    }

                    .dropdown-item {

                        &:hover {
                            padding-left: 2em;
                            @include transition(padding, .2s, ease);
                        }

                        > * {
                            vertical-align: middle;
                            display: inline-block;
                        }
                
                        i {
                            font-size: 2em;
                            display: inline-block;
                            vertical-align: middle;
                            height: 27px;
                            margin-right: 10px;
                        }
                    }
                }                
            }
        }
    }
    /* Fin actions sur la partie droite */   
    
    /* Sitemap */
    .sitemap-btn {
        padding-top: 15px;
        margin-left: 5px;
        height: 45px;
        padding-right: 2px;
      
        i {
          line-height: 0.8em;
          font-size: 2em;
          cursor: pointer;
          color: $white;
        }
    }
      
    .sitemap-container {
        position: fixed;
        width: 0;
        height: 100%;
        background: $white;
        right: 0px;
        top: 0;
        z-index: 10000;
        @include box-shadow(0px 0px 18px 0px rgba(140, 140, 140, 1));
        
        overflow: hidden;
      
        &.show {
          width: 400px;
          
        }
      
        .title {
          height: 53px;
          line-height: 53px;;
          padding: 0 15px;
          text-align: right;
          background: $dark;
          text-transform: uppercase;
          font-size: 1.2em;
          color: lighten($muted, 15%);
          width: 400px;
          text-align: center;
      
          i {
            padding: 0 3px 0 0;
            color: lighten($dark, 50%);
            float: right;
            cursor: pointer;
            line-height: 53px;
          }
        }
      
        form {
          width: 400px;
        }
      
        .app-search {
          position: relative;
      
          input {
            padding: 8px 50px;
            border: 0;
            border-bottom: 1px solid $muted;
            @include border-radius(0);
            text-align: center;
      
            &:focus {
              @include box-shadow(none);
            }
      
            &+i {
              position: absolute;
              top: 5px;
              font-size: 2em;
              right: 14px;
              color: $muted;
            }
          }
      
          typeahead-container {
            margin-top: 0;
            border: 0;
            @include box-shadow(none);
            width: 100%;
            height: calc(100vh - 110px) !important;
      
            button {
              padding: 0;
              height: 52px;

              &.dropdown-item.active, &
              .dropdown-item:active {
                background-color: $primary;
      
                i {
                  color: #fff;
                }
              }
      
              .item-container {
                display: block;
                height: 100%;
                width: 100%;
                padding: 11px 5%;
                position: relative;
      
                &:before {
                  content: "";
                  display: block;
                  width: 90%;
                  height: 1px;
                  position: absolute;
                  bottom: 0;
                  left: 5%;
                  background: $muted;
                }
      
                span {
                  display: inline-block;
                  vertical-align: middle;
                }
      
                i {
                  text-align: center;
                  margin-right: 15px;
                  font-size: 2.0em;
                  display: inline-block;
                  color: $primary;
                  overflow: hidden;
                  width: 30px;
                  vertical-align: top;
                  margin-top: -7px;

                  &.far, &.fas {
                    font-size: 1.6em;
                    margin-top: 0px;
                  }

                  &[class^="vega-"], &[class*=" vega-"] {
                    margin-top: -2px;
                  } 
                }
      
              }
      
              i {
                width: 20px;
                text-align: center;
                margin-right: 15px;
                font-size: 1.3em;
                vertical-align: middle;
                display: inline-block;
                color: $primary;
              }
            }
          }
        }
    }
    /* Fin sitemap */
}

/* typehead de la recherche globale */
.customItemSearch {
    width: 448px;
    display:block;
}

// FIX lp-search-bar : premiere recherche non visible
typeahead-container {
  visibility: visible !important;
}

/* =============
   Menu de lécran (2éme ligne - noir) : breadcrumb + titre + menu application details
============= */

.under-menu {
    background: $dark;
    position: relative;  
    height: $height-menu;

    .under-menu-right {
      width: 90px;;
    }

    i {
      font-size: 32px;
      display: inline-block;
      vertical-align: top !important;
      height: 32px;
      margin-top: 4px;
      margin-right: 10px;

      &[class^="vega-"], &[class*=" vega-"] {
        margin-top: 10px;
      } 

      &.far, &.fas {      
        font-size: 1.9em;
        margin-top: 12px;
      }
    }
  
    .container-fluid {
    
        ul {
            display: inline-block;
            vertical-align: baseline;
            color: $white;
            padding-left: 0;
        }

        > div {
            height: $height-menu;
            white-space: nowrap;
            overflow: hidden;
    
            /* Breacrumb + infos sur la data */
            &.navbar-custom {
              position: static;
              display: inline-block;
              margin-bottom: 0;
              
              i { 
                margin-top: 2px;
                overflow: hidden;

                &[class^="vega-"], &[class*=" vega-"] {         
                  margin-top: 8px;
                }
              }             
        
              &:hover {
                  lp-ui-accordion-menu {
                  display: block !important;
                  @include box-shadow(0px 5px 5px 0px rgba(181, 181, 181, 1));
                  }
              }
              
              
              &.width-dropdown-menu {
                  display: block;
                  width: 100% !important;
              }
              
              ul {
                  margin-bottom: 0;
              
                  > li,              
                  .dropdown-menu {
                   display: inline-block;
                  }
              }
        
            /* titre de l'écran */
            h2 {
                display: inline-block;
                vertical-align: middle;
                font-size: 1.2em;
                color: $white;
                margin: 0;
                line-height: $height-menu;
                height: $height-menu;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;
                overflow: hidden;

                .span-icon {                  
                  height: 50px;
                  display: block;
                  line-height: 43px;
                  float: left;

                  i {                    
                    margin-top: calc( (#{$height-menu} - 32px) / 2) !important;
                    
                    &[class*="lp-"] {
                        margin-top: calc( (#{$height-menu} - 37px) / 2) !important;
                    }
                    
                    &.far, &.fas {                      
                      font-size: 1.6em;
                      margin-top: calc( (50px - 26px) / 2) !important;
                    }
                  }
                }
            
                a {
                  color: $white;
              
                  &:hover {
                      color: lighten($primary, 15) !important;
                  }
                }


            }
            
            /* menu d'ancres vers les accrdéons */
            lp-ui-accordion-menu {
                position: absolute;
                left: 0;  
                top: $height-menu;
                z-index: 1039;
                width: 100%;
                background-color: $white;
                display: none;
            
                li {
                display: inline-block;
            
                >a {
                    display: block; 
                    color: $black !important;
                    font-size: 15px;
                    @include transition(all, .5s, ease);
                    line-height: 20px;
                    padding: 10px 20px;
                    cursor: pointer;
                    text-decoration: none;
                    
                    &.dropdown-toggle {
                    padding: 14px 20px;
                    margin-right: 20px;
                    }
            
                    &:hover {
                    color: lighten($primary, 15) !important;
                    }
                }
                }
            }
            }    
            /* Fin breacrumb + infos sur la data */
            
            /* Menu application details */
            &.col-right-menu {    
            .disabled {
                a {
                color: lighten($dark, 35) !important;
            
                &:hover {
                    i {
                    color: lighten($dark, 35) !important;
                    cursor: not-allowed !important;
                    }
                }
                }
            }
        
            ul {
                margin: 0;
        
                li {
                  margin-left: 10px;    
                  vertical-align: middle;
                  cursor: pointer;
                  display: inline-block;
                  margin-top: 0px;
                  height: $height-menu;
                  width: 50px;
          
                  &.active {
                      i {
                      color: lighten($primary, 15);
                      }
                  }
          
                  a{
                      height: $height-menu;
                      display: block;
                      
                      i {
            
                        &:hover {
                            color: lighten($primary, 15);
                        }
                      }
                  }

                  svg {
                    height: 30px;
                    width: 40px;
                    margin-top: 10px;
                    vertical-align: middle;
                    margin-right: 10px;
                  }
                }
            }
            }    
            /* Fin menu application details */  
        }
    }

    /* Message lorsque le data de l'écran ne peut pas être modifié */
      .alert-readonly {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 0 1.2em;
        color: $danger;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;        
        background: rgba(66, 66, 66, 0.75);
        @include border-radius(4px);
      
        i {
          font-size: 2.05em;
          display: inline-block;
          vertical-align: top !important;
          height: 28px;
          margin-top: 0px;
          margin-right: 10px;
          margin-bottom: 6px;
        }
    }
    /* Fin message lorsque le data de l'écran ne peut pas être modifié */
  }
  
lp-ui-breadcrumb {
  height: 50px;
  display: inline-block;
  vertical-align: middle;

  popover-container {
    top: 30px !important;
    max-width: 100% !important;
    min-width: 500px;

    h4 {
      text-align: left;
      border-bottom: 1px solid $lightdark-alt;
    }
  }

  ul {
    vertical-align: top !important;
    margin-right: 5px;
    height: $height-menu;

    li {
      height: $height-menu;    
      vertical-align: top;

      lp-ui-popover-info {
        display: inline-block;
        vertical-align: middle;
        float: left;
      }

      .item {
        vertical-align: top !important;
        height: $height-menu;
        line-height: 43px !important;
        padding: 0 5px;
        color: $white;        
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        cursor: pointer;

        i {
          margin: 0 !important;

          &[class*="lp-"] {
              margin-top: -5px !important;
          }
        }
      }

      .notsave {
        float: left;
        height: $height-menu;
        line-height: 44px !important;
      }

      .sep {
        line-height: 50px;
        display: inline-block;
        vertical-align: middle;
        font-size: 1.5em;
        font-weight: 300;
        margin: 0 5px;
        float: left;
      }
    }
  }
}


typeahead-container {
  span {               
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Dropdown menu */
.dropdown-menu {
  padding: 4px 0;
  font-size: $base-font-size;
  @include box-shadow(0 3px 12px rgba(0, 0, 0, .05));
}

.dropdown-menu-animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  @include transition(all, 300ms, ease);
  margin-top: 20px !important;
}

.show >.dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0 !important;

  .dropdown-item {
    padding: 6px 24px;
    cursor: pointer;

    &:active {
      color: $dark;
      text-decoration: none;
      background-color: $light;
    }
  }
}

typeahead-container {
  button {

    &.dropdown-item.active, &
    .dropdown-item:active {
      background-color: $primary;

      i {
        color: #fff;
      }
    }
  }
}