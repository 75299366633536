.mat-dialog-container {
	display: block;
	padding: 24px;
	border-radius: 4px;
	box-sizing: border-box;
	overflow: auto;
	outline: 0;
	width: 100%;
	height: 100%;
	min-height: inherit;
	max-height: inherit;
}
.cdk-high-contrast-active .mat-dialog-container {
	outline: solid 1px;
}
.mat-dialog-content {
	display: block;
	margin: 0 -24px;
	padding: 0 24px;
	max-height: 65vh;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.mat-dialog-title {
	margin: 0 0 20px;
	display: block;
}
.mat-dialog-actions {
	padding: 8px 0;
	display: flex;
	flex-wrap: wrap;
	min-height: 52px;
	align-items: center;
	margin-bottom: -24px;
}
.mat-dialog-actions[align="end"] {
	justify-content: flex-end;
}
.mat-dialog-actions[align="center"] {
	justify-content: center;
}
.mat-dialog-actions .mat-button-base + .mat-button-base,
.mat-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
	margin-left: 8px;
}
[dir="rtl"] .mat-dialog-actions .mat-button-base + .mat-button-base,
[dir="rtl"] .mat-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
	margin-left: 0;
	margin-right: 8px;
}

.mat-dialog-container {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: white;
    color: rgba(0, 0, 0, 0.87);
}

/* overlay */
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
}

.cdk-global-overlay-wrapper {
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 1000;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.btn-showWidget {
	display: inline-flex !important;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0;
    right: 26px;
}

lp-modal-customization {
	.mat-tab-body-content {
		> .row {
			margin-left: 0 !important;
			margin-right: 0 !important;
		} 
	}
}