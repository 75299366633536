/*
Template Name: UBold Dashboard
Author: CoderThemes
Email: coderthemes$gmail.com
File: Core
*/

/* =============
  == Core List==

   - Common
   - Helper classes
   - Extra
   - Bootstrap Custom
   - Animation (Some loader)
   - Waves effect
   - Print (Invoice css)

============= */

/* =============
   Common
============= */
//@import '~@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';
//@import '~@swimlane/ngx-datatable/assets/icons.css';
a:not([href]), a:not([href]):hover {
  color: inherit;
}

html {
  position: relative;
  background: $light5;
  body {
    background: $light5;
    font-family: $font-primary;
    margin: 0;
    font-size: $base-font-size;
    /* app loading */

    #home-container {
      background: $logo-bg center center no-repeat; 
      &.noLogo {
        background: none;
      }
    }

    .app-loading {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: $lightdark3;
      @include vertical-align-middle();
      color: $white;
      .svg {
        padding-top: 50px;
        display: inline-block;
        background: $primary-logo no-repeat top center;
        opacity: 1 !important;
      }
    }
    .body-theme {
      padding: 0 0 0px 0;
      width: 100%;
      box-sizing: border-box;
      padding-left: 50px;
      background: no-repeat center center/*$bg-home*/
      darken($white, 13%);
      .container {
        width: 100%;
      }
      &.not-connected {
        vertical-align: middle;
        padding: 0 0 0px 0;
        background: url("/assets/img/login-background.jpg") bottom right no-repeat;
        width: 100vw;
        height: 100vh;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  min-height: 21px;
}

h1 {
  line-height: 53px;
  font-size: 56px;
  font-weight: 300;
  padding: 10px 0 10px 170px;
  margin: 0;
  color: $vega-orange;
  background: center center no-repeat $primary-logo;

  min-height: 53px;
  display: inline-block;
  vertical-align: middle;
}

h2 {
  line-height: 35px;
  font-size: 30px;
}

h3 {
  line-height: 30px;
  font-size: 24px;
  small {
    color: $lightdark4;
  }
}

h4 {
  line-height: 22px;
  font-size: 18px;
  small {
    color: $lightdark4;
  }
}

h5 {
  font-size: 15px;
  small {
    color: $lightdark4;
  }
}

h6 {
  font-size: 12px;
}

ul {
  li {
    list-style: none;
    padding-left: 0;
  }
}

.container {
  width: auto;
  max-width: 100%;
}

p {
  line-height: 1.6;
}

* {
  outline: none !important;
}

b {
  font-weight: 600;
}

a {
  color: $dark;
  
  &:hover {
    outline: 0;
    text-decoration: none;
  }
  &:active {
    outline: 0;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

.container {
  width: auto;
}

.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-primary {
  color: #fff !important;
  border-color: $primary;
  background: $primary;

  &:disabled {
    border-color: $primary;
    background: $primary;
  }
  
  &:hover,
  &:focus,
  &:active {
    background: darken($primary, 10%) !important;
    border-color: darken($primary, 10%) !important;
    @include box-shadow(none !important);
  }
}

.btn-outline-primary {
  color: $primary !important;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background-color: darken($primary, 10%) !important;
    border-color: darken($primary, 10%) !important;
    @include box-shadow(none !important);
  }
}

/* Footer */

.footer {
  border-top: 1px solid rgba($muted, 0.2);
  bottom: 0px;
  color: $muted;
  text-align: left !important;
  padding: 19px 30px 20px;
  position: absolute;
  right: 0px;
  left: 250px;
}

#wrapper {
  height: $height;
  overflow: hidden;
  width: $width;
}

.page {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

/* Page titles */

.page-title {
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 70px;
}

.header-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 8px;
  margin-top: 0;
}

/* =============
   Helper clasess
============= */

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-l-r-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.m-h-40 {
  min-height: 40px;
}

.m-h-50 {
  min-height: 50px;
}

.l-h-34 {
  line-height: 34px;
}

.font-600 {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: normal;
}

.font-light {
  font-weight: 300;
}

.font-13 {
  font-size: 13px !important;
}

.wrapper-md {
  padding: 20px;
}

.pull-in {
  margin-left: -20px;
  margin-right: -20px;
}

.b-0 {
  border: none !important;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.bx-shadow {
  @include box-shadow(0px 1px 2px 0px rgba(0, 0, 0, 0.1));
}

.bx-shadow-none {
  @include box-shadow(none !important);
}

.mx-box {
  max-height: 380px;
  min-height: 380px;
}

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.thumb-xl {
  height: 120px;
  width: 120px;
}

.add-new-plus {
  height: 32px;
  text-align: center;
  width: 32px;
  display: block;
  line-height: 32px;
  color: #98a6ad;
  font-weight: 700;
  background-color: #ebeff2;
  @include border-radius(50%);
}

/* =============
   Extras
============= */

/* Grid page */

.grid-structure {
  .grid-container {
    background-color: $lightdark;
    margin-bottom: 10px;
    padding: 10px 20px;
  }
}

/* =============
   Bootstrap-custom
============= */

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.container,
.container-fluid,
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}

.blockquote {
  font-size: 16px;
}

.dropdown-lg {
  width: 260px;
}

.arrow-none {
  &:after {
    border: none;
    margin: 0;
    display: none;
  }
}



.nav-pills>.active>a>.badge {
  color: $custom;
}


/* Pre */

pre {
  background-color: $lightdark;
  border: 1px solid #D4D8DA;
}

/* Owl */

.slider-bg {
  background-size: cover !important;
  padding: 5.5% 4.5%;
}

/* Media */

.media {
  margin-bottom: 20px;
  .media-heading {
    font-weight: 600;
    font-size: 16px;
  }
}

.media:last-of-type {
  margin-bottom: 0;
}

/* =============
   Animation
============= */

/* Progressbar Animated */

@-webkit-keyframes animationProgress {
  from {
    width: 0;
  }
}

@keyframes animationProgress {
  from {
    width: 0;
  }
}

/* Portlets loader */

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

// Need over 1050 for workflows (input date modal)
.cdk-overlay-container {
  z-index: 1040 !important;
}

/* FIX bootstrap 5 */

.form-group {
  margin-bottom: 1rem;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto, .container, .container-fluid {
  position: relative;
}

select {
  appearance: auto !important;
}