@media print {
    .page-title-box,
    .topbar-left,
    .hidden-print,
    .breadcrumb,
    .page-title,
    .footer,
    #topnav,
    .btn-custom {
      display: none;
      margin: 0px;
      padding: 0px;
    }
    .left,
    .right-bar {
      display: none;
    }
    .wrapper {
      padding-top: 0 !important;
    }
    .content-page {
      margin-left: 0px;
      margin-top: 0px;
    }
  }