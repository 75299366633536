.mat-slide-toggle {
	display: inline-block;
	height: 24px;
	max-width: 100%;
	line-height: 24px;
	white-space: nowrap;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
	transform: translate3d(16px, 0, 0);
}
[dir="rtl"] .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
	transform: translate3d(-16px, 0, 0);
}
.mat-slide-toggle.mat-disabled {
	opacity: 0.38;
}
.mat-slide-toggle.mat-disabled .mat-slide-toggle-label,
.mat-slide-toggle.mat-disabled .mat-slide-toggle-thumb-container {
	cursor: default;
}
.mat-slide-toggle-label {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	height: inherit;
	cursor: pointer;
}
.mat-slide-toggle-content {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.mat-slide-toggle-label-before .mat-slide-toggle-label {
	order: 1;
}
.mat-slide-toggle-label-before .mat-slide-toggle-bar {
	order: 2;
}
[dir="rtl"] .mat-slide-toggle-label-before .mat-slide-toggle-bar,
.mat-slide-toggle-bar {
	margin-right: 8px;
	margin-left: 0;
    background-color: rgba(0, 0, 0, 0.38);
}
[dir="rtl"] .mat-slide-toggle-bar,
.mat-slide-toggle-label-before .mat-slide-toggle-bar {
	margin-left: 8px;
	margin-right: 0;
}
.mat-slide-toggle-bar-no-side-margin {
	margin-left: 0;
	margin-right: 0;
}
.mat-slide-toggle-thumb-container {
	position: absolute;
	z-index: 1;
	width: 20px;
	height: 20px;
	top: -3px;
	left: 0;
	transform: translate3d(0, 0, 0);
	transition: all 80ms linear;
	transition-property: transform;
}
._mat-animation-noopable .mat-slide-toggle-thumb-container {
	transition: none;
}
[dir="rtl"] .mat-slide-toggle-thumb-container {
	left: auto;
	right: 0;
}
.mat-slide-toggle-thumb {
	height: 20px;
	width: 20px;
    border-radius: 50%;    
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: #fafafa;
}
.mat-slide-toggle-bar {
	position: relative;
	width: 36px;
	height: 14px;
	flex-shrink: 0;
	border-radius: 8px;
}
.mat-slide-toggle-input {
	bottom: 0;
	left: 10px;
}
[dir="rtl"] .mat-slide-toggle-input {
	left: auto;
	right: 10px;
}
.mat-slide-toggle-bar,
.mat-slide-toggle-thumb {
	transition: all 80ms linear;
	transition-property: background-color;
	transition-delay: 50ms;
}
._mat-animation-noopable .mat-slide-toggle-bar,
._mat-animation-noopable .mat-slide-toggle-thumb {
	transition: none;
}
.mat-slide-toggle .mat-slide-toggle-ripple {
	position: absolute;
	top: calc(50% - 20px);
	left: calc(50% - 20px);
	height: 40px;
	width: 40px;
	z-index: 1;
	pointer-events: none;
}
.mat-slide-toggle
	.mat-slide-toggle-ripple
	.mat-ripple-element:not(.mat-slide-toggle-persistent-ripple) {
	opacity: 0.12;
}
.mat-slide-toggle-persistent-ripple {
	width: 100%;
	height: 100%;
	transform: none;
}
.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
	opacity: 0.04;
}
.mat-slide-toggle:not(.mat-disabled).cdk-keyboard-focused
	.mat-slide-toggle-persistent-ripple {
	opacity: 0.12;
}
.mat-slide-toggle-persistent-ripple,
.mat-slide-toggle.mat-disabled
	.mat-slide-toggle-bar:hover
	.mat-slide-toggle-persistent-ripple {
	opacity: 0;
}
@media (hover: none) {
	.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
		display: none;
	}
}
.cdk-high-contrast-active .mat-slide-toggle-thumb,
.cdk-high-contrast-active .mat-slide-toggle-bar {
	border: 1px solid;
}
.cdk-high-contrast-active
	.mat-slide-toggle.cdk-keyboard-focused
	.mat-slide-toggle-bar {
	outline: 2px dotted;
	outline-offset: 5px;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
    background-color: black;
}

.mat-ripple {
    overflow: hidden;
    position: relative;
}

.mat-ripple:not(:empty) {
    transform: translateZ(0);
}

.mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    -ms-transform: scale(0);
    transform: scale(0);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $primary;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: transparentize($primary, 0.54);
}