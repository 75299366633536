
lp-menu {
  #menu {
    background: $primary;

    .topbar-left-menu {
      width: calc(100% - 190px);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;

      h1 {
        position: absolute;
        left: 20px;
        top: 0;
      }

      lp-search-bar {
        margin-left: 190px;

        .global-search {
          $border-radius : 25px;
          span {
            width: 324px;
            border-bottom-left-radius: $border-radius;
            border-top-left-radius: $border-radius;            
            background-color: rgba(255, 255, 255, 0.85);

            input {
              border-bottom-left-radius: $border-radius;
              border-top-left-radius: $border-radius;
              background: transparent;
            }
          }

          select {
            border-bottom-right-radius: $border-radius;
            border-top-right-radius: $border-radius;            
            background-color: rgba(255, 255, 255, 0.85);
          }

          button {
            margin-left: 5px;
            display: inline-flex;
            line-height: 1.1em;
            align-items: baseline;

            .vega-add:before {
              content: "+";
              font-weight: 200;
            }
          }
        }
      }
    }

    .topbar-right-menu {        
      width: 170px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > li {
        float: none;

        &.sitemap-btn {
          display: none;
        }
      }
    }

    .left-menu {
      top: 110px;   
      width: auto;   
      box-shadow: none;
      background: transparent;

      lp-outbar {
        li {          
          width: 65px;
          
          &:first-child {
            display: none;
          }

          a.activeVerb {
            color: $primary;
          }
        }

      }

      .bottom {
        background: transparent;
      }
    }
  }

  &.mainZoomShowed {
    #menu {
      .left-menu {
        top: 55px;  
      }
    }
  }
}

&.dashboard {
  lp-menu {
    #menu {
      .left-menu {
        top: 55px;
      }
    }
  }
}
.under-menu {
  background-color: #000000;

  .col-right-menu {   
    ul {
      li {
        &.active {
          i {
          color: $secondary;
          }
        }
  
        a{
          i {  
            &:hover {
                color: $secondary;
            }
          }
        }
      }
    }
  }  

  .container-fluid > div.navbar-custom lp-ui-accordion-menu {
    z-index: 1081;

    li > a:hover {
      color: $secondary !important;
    }
  }
}

#formulaire {
  background-color: transparent;
}

$border-radius : 10px;
$box-shadow: 0px 0px 10px 0px #c5c5c5;
lp-ui-accordion-group {
  box-shadow: $box-shadow;
  display: block;
  border-radius: $border-radius;

  > lp-panel {
    border-radius: $border-radius;

    .panel-body {
      border-top: 1px solid #ccc;
    }
  }
}

lp-home {
  .gridster-item-inner {
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    > lp-widget > lp-card > div {     
      border-radius: $border-radius;
    }
  }
}

.card-header {
  background-color: transparent;
  border: 0;
}

lp-application-item-details {
  .panel-group {
    .card {
      box-shadow: $box-shadow;
      border-radius: $border-radius;

      .modal-body {
          border-top: 1px solid #ccc;
      }
    }
  }
}

&.planning,
&.mainZoomBody {
  .left-menu {
    top: 110px !important;
  } 
}

h2 i {    
  margin-left: 7px;
  margin-right: 24px;
}

$sidebarItemSize: 44px;
$sidebarItemRadius: 25px;
$sidebarItemMargin: 5px;
.sidebar-item:not(.no-float),
.sidebar-more-item {
  width: $sidebarItemSize;
  height: $sidebarItemSize;
  border-radius: $sidebarItemRadius;
  margin-bottom: $sidebarItemMargin;
  margin-right: $sidebarItemMargin;
  border: 1px solid #b8b8b8 !important;

  &:not(.hide) {
    > .content-more {
      border-top-left-radius: $sidebarItemRadius;
      border-bottom-left-radius: $sidebarItemRadius;
    }
  }

  &:hover {
    background-color: #8cc5c5;
    color: #fff;
  }
}

.lp-common-tools {
  top: 113px;
}

.more-item {  
  margin-right: $sidebarItemMargin;
  height: $sidebarItemSize;
  margin-bottom: $sidebarItemMargin;
  width: 142px;

  .content-more {
    background: transparent;
    border: 0;
    display: flex;
  }
  
  .fa.fa-ellipsis-h {
    border: 1px solid #b8b8b8;
    margin-right: $sidebarItemMargin;
    display: block;
    border-radius: $sidebarItemRadius;
    height: $sidebarItemSize;
    width: $sidebarItemSize;
    line-height: 44px;
    background: #fff;
    padding: 0;    
    padding-right: 2px;
  }
}

.container-2-cols {
  > .card-box:first-child {
    padding-right: 60px;
  }
}

$lpSwitcherSize: 32px;
$lpSwitcherRadius: 25px;
lp-switcher .switcher-container {
  border-radius: $lpSwitcherRadius;
  .switcher-item {
    line-height: $lpSwitcherSize;  
    border-radius: $lpSwitcherRadius;
  }
}

.addContract,
.createContract {
  border-radius: $lpSwitcherRadius;
  width: $lpSwitcherSize;
  height: $lpSwitcherSize;
  line-height: 28px;  
  padding: 0;
  text-align: center;
}

.addContract {
  width: auto;
  padding: 0 10px;
}

lp-active {
  label {
    position: static;
    width: 100%;
    height: 100%;
    border: 0;

    &.checked {
      background-color: #25bb65;
    }
  }
}

lp-right-col {
  .modal-body {
    padding: 0.5rem !important;
  }
}

.lpactive {
  justify-content: center;
  display: flex;
  lp-active {
    width: 25px;
    height: 25px;
  }
}

.lpSwitchTheme {
  overflow: auto;
}

lp-application-item-details ngx-datatable .applicationItemDetailAction, .tableWithInput ngx-datatable .applicationItemDetailAction {  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 0px !important;
}