// Attention : pas importé
.userEvent-container {
  position: absolute;
  bottom: -1px;
  left: calc(100% + 1px);
  background: $white;
  border: 0px solid $black;
  max-height: 0;
  max-width: 350px;
  width: 0;
  overflow: hidden;

  .noEvent {
    width: 350px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 1.3em;
    text-align: center;
  }

  lp-user-event {
    width: 350px;

    ul {
      text-align: left;
      position: relative;

      &:before {
        content: '';
        display: block;
        height: 100%;
        top: 20px;
        left: 324px;
        width: 2px;
        background: lighten($muted, 20%);
        position: absolute;
        z-index: 1;
      }

      li {
        text-align: left;
        position: relative;
        z-index: 2;
        width: 350px;
        padding: 10px 10px 20px 15px;
        margin-bottom: 5px;

        .title {
          font-weight: bold;
          font-size: 1em;
          display: block;
          height: auto;
          width: auto;
        }

        i {
          background: #dbdbdb;
          @include border-radius(50%);
          font-size: 1.5em;
          display: block;
          padding-top: 2px;
          vertical-align: middle;
          width: 30px;
          height: 30px;
          margin-right: 15px;
          text-align: center;
          float: left;
          margin-bottom: 15px;

          &.type {
            float: right;
            font-size: 1.2em;
            color: $white;
            padding-top: 6px;
            background-color: #0be881;
            margin-right: 0;

            &.show {
              background-color: #74b9ff;
            }

            &.modify {
              background-color: #ffc048;
            }

            &.delete {
              background-color: #ef5777;
            }
          }
        }
      }
    }
  }

  &.show {
    border: 1px solid $lightdark-alt;
    max-height: calc(100% + 1px);
    width: 350px;
    overflow: hidden;
    @include transition(all, 500ms, ease);
  }
}