.container-fluid {
  max-width: 100%;
  margin: 0 auto;
  float: none;
}

.container-fluid-2-cols {
  position: relative;
  padding: 0;

  .container-2-cols {
    position: relative;
    padding: 0;
    height: calc(100vh - 103px);

    @media screen and (max-width: 767px) {
      height: auto !important;    
    }

    > div {
      overflow-y: scroll;
      height: 100%;
      margin: 0;
          
      @media screen and (max-width: 767px) {
        overflow-y: auto;
      }


      &:first-child {
        padding-right: 35px;
        position: relative;
          
        @media screen and (max-width: 767px) {
          padding-right: 10px;
        }
      }
      &:last-child {
        padding-left: 35px;
        padding-right: 35px;
        background: url("/assets/img/right-col-background.png") bottom center no-repeat;
        background-color: #f1f1f1;
        background-size: contain;
        border-radius: 0;
          border-left: 1px solid #d2d2d2;
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$lightdark-alt+0,000000+100&0.65+0,0+100 */
          background: -moz-linear-gradient(left, $lightdark-alt 0%, rgba(0, 0, 0, 0) 10px);
          /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(150, 150, 150, 0.616) 0%, rgba(0, 0, 0, 0) 10px);
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(150, 150, 150, 0.65) 0%, rgba(0, 0, 0, 0) 10px);
          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$lightdark-alt, endColorstr=$black, GradientType=1);
          /* IE6-9 */
          
          @media screen and (max-width: 767px) {
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
          }
      }
    }
  }
  
  .pageTransition {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0;
      background: darken($white, 13%);
      z-index: 1040;
      @include box-shadow(-10px 0px 50px 0px rgba(0, 0, 0, 0.75));
  
      &.show {
      transition-property: width;
      transition-duration: 0.25s;
      transition-timing-function: linear;
      transition-delay: 0s;
      width: 100%;
      }
    }
  }
  

/* Modification de la largeur des colonnes */
  .dragme {
    position: absolute;
    left: calc(50% + 10px);
    top: 0;
    width: 10px;
    background: transparent;
    z-index: 1;
    height: 100%;
    cursor: col-resize !important;
  
    &:active {
      cursor: col-resize !important;
      background: -moz-linear-gradient(left, $lightdark-alt 0%, rgba(0, 0, 0, 0) 100%);
  
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(204, 204, 204, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(204, 204, 204, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$lightdark-alt, endColorstr=$black, GradientType=1);
  
      /* IE6-9 */
    }
  }
  
  [draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }
  /* Fin modification de la largeur des colonnes */