&.not-connected {
    background: url(/assets/img/bg-login-irium-2k23.jpg) center center no-repeat;
    background-size: 100vw 100vh;

    &:after {
        content: "";
        display: block;
        height: 255px;
        width: 447px;
        background: url(/assets/img/logo-login-irium-2k23.png) center center no-repeat;
        position: fixed;
        right: 0;
        bottom: 0;
    }


    lp-login > .col > .col {
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 30px;
        padding: 10px 40px;
        margin: 0;
        width: 100%;        
        max-width: 420px;

        .logos {
            margin-top: 10px;
            background: url(/assets/img/logo-lp3k-white.png) no-repeat center center;
        }

        form {
            margin-top: 0 !important;
        }

        h4 {
            font-style: italic;
            color: #fff;
            font-weight: normal;
        }

        .login-bloc h4 {
            display: none;
        }

        label {
            display: block !important;                    
            margin-bottom: 5px;
            font-size: 1.2em;
            font-style: italic;
            color: rgba(255, 255, 255, 0.7);
        }

        .login-card {
            background: transparent;
            border: 0;
            padding: 0;

            .form-group  {
                box-shadow: -1px 0px 15px 1px #386566;
                input {
                    background: rgba(255, 255, 255, 0.1);
                    border: 0;
                    padding: 10px;
                    color:#fff;
                    font-style: italic;

                    &:focus {
                        box-shadow: none;
                    }

                    &::placeholder {
                        color:rgba(255, 255, 255, 0.3);
                        font-style: italic;
                    }
                }

                .input-group-addon {
                    display: none;
                }
            }


            button {
                width: 100%;
                background: rgba(255, 255, 255, 0.5);
                color: #008182 !important;
                padding: 10px;
                border: 0;
                font-style: italic;
                font-size: 1.2em;
                

                &.btn-transparent {
                    background: transparent !important;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            a {                
                color: #fff !important;
                font-size: 1.2em;
            }

            .translateFlags {
                float: none !important;
            }

            .bottom-links {
                display: flex;
                flex-direction: column-reverse;
                border-top: 2px solid rgba(255,255,255,0.5);
                margin-top: 25px;
                align-items: flex-start;

                a {
                    margin-top: 10px !important;
                    margin-bottom: 5px;                    
                    text-decoration: none;
                    font-style: italic;

                    i {
                        display: none;
                    }

                    &:hover {
                        font-style: italic;
                    }
                }
            }
        }
    }

    .irium-link {
        display: none;
    }
}