router-outlet + lp-home, 
router-outlet + * > lp-top-banner + .container-fluid {
  padding-left: 60px;
}

.body-scheduler .scheduler {
  padding-left: 70px;
}

H1 {
    background: $primary-logo left center no-repeat;
    left: 5px !important;
    background-size: contain;
}

i {
  .mat-badge-content {
    font-family: $font-primary;
    font-size: $base-font-size;
    background-color: $secondary;
  }
}

#menu .left-menu lp-outbar ul li a.activeVerb,
#menu .left-menu lp-outbar ul li a:hover {
  color: $secondary !important;
}