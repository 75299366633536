// FIX NODE MODULE !
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 1em;
}

// Large devices (desktops, less than 1300px)
@media (max-width: 1400px) {
    html {
        body {      

            // menu fond noir
            .under-menu {
                .col-right-menu ul li {
                    margin-left: 10px !important;
                }
            }

            // Champs de formulaire                          
            #{$lp-forms-components} {
                max-width: 95% !important;

                > span {
                    .form-control {     
                        height: 30px;
                        padding: 0 5px;
                    }

                    .input-group-btn {
                        width: 30px;
                    }

                    .icon-search {
                        font-size: 1em;
                        margin-top: 3px;
                    }

                    .fa-calendar-check-o {
                        font-size: 0.85em;
                        display: block;
                    }

                    .btn-scheduler {    
                      right: 31px;
                    }

                    .waves-effect waves-light {
                        font-size: 1.3em;
                    }
                }
            }

            lp-zoom-field > div {
                max-width: 100% !important;
            }

            lp-label-field {
                label { 
                    font-size: 1em !important;
                }
            }

            /* =============
            Panels left
            ============= */
            .panel-group {
                .panel {
                    margin-bottom: 10px;

                    .panel-heading {
                        padding: 5px 10px;

                        .title-buttons {                            
                            right: 5px;
                            top: 3px;

                            .btn {
                                padding: 0 5px;
                            }
                        }
                    }
                    .panel-heading +.panel-collapse {
                        .panel-body {
                            padding: 10px 5px;
                            
                            .form-group.col-xl-6 {
                                padding: 0;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }

            /* =============
            col right
            ============= */

            .container-2-cols > .col-md-6:last-child {
                padding-left: 5px;
                padding-right: 5px;

                .modal-body {
                    padding: 5px;;
                }
            }
        }
    }
}

// Large devices (desktops, less than 1300px)
@media (max-width: 1299px) {
    html {
        body {      
            font-size: 10px;

            // menu fond noir
            .under-menu {
                .col-right-menu ul li {
                    margin-left: 0px !important;
                }
            }

            .navbar-custom {
                h2 {
                    text-overflow: ellipsis;    
                    overflow: hidden;
                }
            }

            #menu .left-menu ul li a {
                height: 30px;
            }
        }
    }
}