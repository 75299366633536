.lp-ui-loader {
    opacity: 0;
    color: $white;
    @include vertical-align-middle();
    position: fixed;
    z-index: 999998;
    overflow: hidden;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
  
    &.loader-absolute {
      position: absolute;
      color: $dark;
      width:300px;
  
      .svg {
        width: 75px;
        margin-right: 10px;
      }
    }
  
    &.show {
      opacity: 1;
      width: 100%;
      height: 100%;
    }
  
    &:not(.show) {
      @include transition(opacity, 0.5s, ease-in-out);
      @include transition(width, 100ms, ease-in-out);
      @include transition(height, 100ms, ease-in-out);
      transition-delay: 1s, 1s, 0.5s;
      transition-property: width, height, opacity;
    }
  
    .overlay {
      @include vertical-align-middle();
      @include transition(opacity, 200ms, ease-in-out);
      @include border-radius(4px);
      background: $bg-overlay;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  
    div:not(.overlay) {
      position: relative;
      z-index: 2;
  
      span {
        display: block;
        text-align: center;
  
        svg {
          max-height: 150px;
        }
      }
    }
  
    &.no-overlay {
      height: auto;
      width: auto;
      bottom: 5px;
      width: 100%;
      left: 0%;
      pointer-events: none;
  
      &.top {
        bottom: auto;
        top: 5px;
      }
  
      div {
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.75);
        color: $dark;
        padding: 5px 75px 5px 20px;
        @include border-radius(4px);
        @include box-shadow(0px 0px 14px -3px rgba(0, 0, 0, 0.75));
  
        span {
          display: inline-block;
          vertical-align: middle;
          text-align: left;
  
          svg {
            max-width: 40px;
            margin-right: 20px;
          }
        }
      }
    }
}

lp-ui-loader-mini {
    background: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 32px; 
  
    svg {
      display: inline-block;
      width: 28px;
      margin-right: 15px;
      margin-bottom: 2px;
    }
  }