/* =============
   Général
============= */

.alert-info {
  border-color: $primary;
  color: $primary;
}

pre {
  color: $fontColor;
}

/* =============
   Panels
============= */

.panel-group {
  .panel {
    .panel-heading { 

      h5 {
        color: $fontColor;
      }

      &:hover {
        h5 {
          color: $primary;
        }
      }
    }
  }
}


/* =============
   Forms
============= */

/*inputs / select */
#{$lp-forms-components} {
  >span {
    .form-control {
      color: $inputColor;



      &:focus {
        color: $inputColor;
      }
    }
  }
}

lp-label-field {
  color: $fontColor;
}

// Checkbox and Radios

.checkbox {
  label {
    color: $fontColor;

    &:hover {
      &:before {
        background-color: lighten($primary, 10%);
        border-color: lighten($primary, 10%);
      }
    }
  }

  &.checked {
    label {
      &:before {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

/* switcher */
lp-switch-box {
  .switch-toggle.switch-candy {

    label {
      color: $fontColor;

      &:not(:checked):hover {
        border-color: $focusbtn;
      }

    }

    input:checked+label {
      color: $white;
    }

    input:checked~a {
      background: $primary;
    }
  }
}

/* bouton radio */

.custom-radio {
  color: $fontColor;

  .custom-control-indicator {
    border: 1px solid lighten($muted, 19%);
  }

  &:hover {
    .custom-control-indicator {
      background-color: lighten($primary, 10%);
    }
  }

  input:checked+.custom-control-indicator {
    background-color: $primary !important;
  }
}


/* =============
  Menu
============= */

#menu {
  background-color: #f1f1f1;
  height: 53px;
  padding-top:7px;
  padding-bottom: 7px;
  box-sizing: border-box;
  position: relative;

  h1 {
    line-height: 38px;
    min-height: 35px;
    background-size: 35px auto;
  }


  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 7px;
    width: 100%;
    background: $primary;
  }

  lp-search-bar {
    height: 32px;
    display: inline-block;
    vertical-align: top;
    margin-top: -3px;
  }

  .newScope {
    color: $dark;    
    line-height: 36px;
  }

  .nav-user {
    i {
      color: $dark !important;
    }
  }

  .sitemap-btn {
    padding-top: 5px;

    i {
      color: $dark;
    }
  }

  .topbar-right-menu .btn-group .nav-link {
    line-height: 30px;    
    margin-bottom: 6px;
  }
  
  .left-menu ul li a:hover {
    color: $primary;
  }  

  .noimg {
    filter: none !important;
  }
 
}

  .under-menu .col-right-menu ul li {
    position: relative;
    i, i:hover {
      color: $white !important;
    }

    &:hover {
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background: $white;
        opacity: 0.5;
      }
    }


    &.active {
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background: $white;
      }
    }

    &.disabled {
      opacity: 0.5;

      &:after {
        display: none;
      }
    }
  }

datatable-header {
  .datatable-row-center {
    border-bottom: 5px solid #000;
  }
}

.modal-input, 
lp-currency-v2, 
lp-currency, 
lp-date-month, 
lp-date-time, 
lp-date, 
lp-date-time-range,
lp-dropdown-field, 
lp-number, 
lp-text-field, 
lp-textarea-field, 
lp-time, 
lp-zoom-field > div, 
lp-zoom-field {
  > span .form-control{
    &:focus {
      border: 2px solid #000 !important;
    }

    &.is-invalid {
      border: 2px solid $primary;
    }
  }
  
}


ul {
  >li {
    >a {

      //color: $fontColor;    
      &:hover {
        color: lighten($primary, 10%);
      }
    }
  }
}

.navbar-custom {
  ul {

    >li,
    lp-ui-accordion-menu>li {
      >a {
        &:hover {
          color: $primary !important;
        }
      }
    }
  }
}

.under-menu {
  h2 {
    a {
      &:hover {
        color: $primary !important;
      }
    }
  }

  .col-right-menu {
    ul {
      li {
        &.active {
          i {
            color: lighten($primary, 15);
          }
        }

        i {

          &:hover {
            color: lighten($primary, 15);
          }
        }
      }
    }
  }
}




/* =============
   Tables
============= */



.table {
  color: $fontColor;

  tr:hover {
    input.form-control {
      background-color: transparent !important;
    }
  }

  input.form-control {
    color: $fontColor;

    &:focus {
      color: $white;
      border-color: $primary !important;
    }

    &:disabled,
    &[readonly] {
    }
  }
}

.table-hover>tbody>tr:hover {
}

.datatable-editable {
  input.form-control {
    background: transparent;
  }
}

.ngx-datatable.material {
  color: $fontColor;

  .datatable-header-inner {
    .datatable-header-cell {
      color: $fontColor;
    }
  }

  .datatable-header {
    border-color: darken($lightdark, 5%);
  }

  &:not(.cell-selection) .datatable-body-row {
    border-bottom: 1px solid darken($lightdark, 5%);

    .datatable-body-cell {
      color: $fontColor;
    }
  }

  &:not(.cell-selection) .datatable-body-row:hover,
  &:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {

    .datatable-body-cell {
      color: lighten($fontColor, 15%);
    }
  }

  datatable-summary-row {
    datatable-body-row {

      datatable-body-cell {
        color: lighten($fontColor, 25%);
      }      
    }
  }
}

.tooltip {
  opacity: 100;
}

datatable-row-wrapper:nth-child(odd) .datatable-row-group {

}
/* =============
  modal
============= */

.modal-content {
  background-color: $white;
  border: $muted;
  color: $fontColor;
}


#formulaire {
  background: transparent;
}

.sidebar-item.no-float {
  color: $fontColor;

  &:hover {
    color: $primary;
  }
}

.boolean-true,
.boolean-false {
  border-color: $fontColor;
}

.boolean-true {
  background-color: $fontColor;
} 

/* =============
  SCHEDULERS
============= */

.odd {
  background-color: $scheduler-odd !important;
}

.white {
  background-color: $scheduler-white !important;
}

.light-grey {
  background-color: $scheduler-light-grey !important;
}

.grey {
  background-color: $scheduler-grey !important;
}

.black {
  background-color: $scheduler-black !important;
}

.light-green {
  background-color: $scheduler-light-green !important;
}

.green {
  background-color: $scheduler-green !important;
}

.light-yellow {
  background-color: $scheduler-light-grey !important;
}

.yellow {
  background-color: $scheduler-yellow !important;
}

.light-orange {
  background-color: $scheduler-light-orange !important;
}

.orange {
  background-color: $scheduler-orange !important;
}

.light-red {
  background-color: $scheduler-light-red;
}

.red {
  background-color: $scheduler-red;
}

.light-blue {
  background-color: $scheduler-light-blue;
}

.blue {
  background-color: $scheduler-blue;
}

.theme-color {
  background-color: $scheduler-theme-color;
}

.dhx_timeline_data_cell.today {
  background-color: $primary;
}

// tableau 
ngx-datatable {
  datatable-header {
    background: $primary;
  }
}

.scheduler-right-button {
  i, i:hover {
    color: $white !important;
  }

  &:hover {
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      width: 100%;
      background: $white;
      opacity: 0.5;
    }
  }


  &.active {
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      width: 100%;
      background: $white;
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $primary;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: transparentize($primary, 0.54);
}

.submit-form-buttons {
  *:hover {
    color: $primary;
  }
}

::-moz-selection { background : transparentize($primary, 0.75); }
::selection { background : transparentize($primary, 0.75); }

.mat-progress-spinner.mat-accent circle, .mat-progress-spinner.mat-warn circle, .mat-progress-spinner circle, .mat-spinner.mat-accent circle, .mat-spinner.mat-warn circle, .mat-spinner circle {
  stroke: $primary;
}

.under-menu .container-fluid>div.navbar-custom lp-ui-accordion-menu li>a:hover {
  color: $primary!important;
}

.transparent-btn {
  &:hover {
    color: $primary;
  }
}

.modal-workflow {
  .transparent-btn {
    &:hover {
      color: $primary;
    }
  }
 
}

#menu .sitemap-container .app-search typeahead-container button .item-container i,
.mat-simple-snackbar-action  {
  color: $primary;
}

typeahead-container button.dropdown-item,
#menu .sitemap-container .app-search typeahead-container button.dropdown-item {
  &.active,
  &:active {
    background-color: $primary;
    color: #fff;

    i {
      color: #fff;
    }
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar,
.dhx_wrap_section .dhx_cal_radio input:checked~a, 
.dhx_wrap_section .switch-toggle.switch-candy input:checked~a,
lp-switch-box .dhx_cal_radio input:checked~a,
lp-switch-box .switch-toggle.switch-candy input:checked~a,
.dhx_cal_event_line {
  background-color: $primary;
}

.card-accent-info {
  border-top-color: $dark !important;
}

lp-zoom-field .btn-update:hover {
  color: $primary;
}


lp-mail-report,
lp-print-report {
  button {
    &:hover {
      color: $primary;
    }
  }
}

.dhx_wrap_section .dhx_cal_radio label:not(:checked):hover,
.dhx_wrap_section .switch-toggle.switch-candy label:not(:checked):hover,
lp-switch-box .dhx_cal_radio label:not(:checked):hover,
lp-switch-box .switch-toggle.switch-candy label:not(:checked):hover {
  border-color: lighten($primary,15);
}

.memos div:hover {
  color: $primary
}

/* =============
  Badge de couleur principale
============= */
.lp-common-tools {
  i {
   .mat-badge-content {
      background-color: $primary !important;
    }
  }
}


lp-top-banner {
  .mat-badge-content {
    background-color: $primary;
  }
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: lighten($primary, 50%) !important;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background-color: darken($primary, 10%) !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background-color: $primary;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto,Helvetica Neue,sans-serif;
}

/* FIX 2024 */

#menu {
  .topbar-right-menu .btn-group .nav-link {
    height: 35px;
  }

  .sitemap-btn i {
    margin-top: 2px;
    display: block;
  }
}

lp-switcher .switcher-container {
  height: 36px;
  border-radius: 4px;

  .switcher-item {
    line-height: 32px;
    border-radius: 4px;
  }
}

.lp-common-tools.cols-2 {
  right: calc(50% - 12px);

  .sidebar-item:not(.no-float):hover, .lp-common-tools .sidebar-more-item:hover {
    color: $primary;
  }
}

lp-chips .checkbox {
  background-color: rgba($primary, 0.1);
}


.mat-mdc-tab {
  .mdc-tab__text-label {
    color: #000000;
  }

  .mdc-tab-indicator__content--underline {
    border-color: $light; 
    opacity: 1;
  }
  
  &.mdc-tab--active {
    .mdc-tab__text-label {
      color: $primary;
    }
    .mdc-tab-indicator__content--underline {
      border-color: $primary; 
    }
  }
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab.mdc-tab--active .mat-ripple-element, .mat-mdc-tab .mdc-tab__ripple::before {
  background-color: lighten($primary, 40%);
}