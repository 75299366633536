lp-login,
lp-maintenance {
    @include vertical-align-middle();  
    height: 100vh;
    
    .login-card {
      margin-bottom: 0;
    }
  
    .logos {
      background: left center no-repeat $logo-login-left, right center no-repeat $logo-login-right;
      background-size: auto auto, 37% auto;
      height: 50px;
    }

    .input-group-addon {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      
      button {
        width: 46px;
      }
    }
  }