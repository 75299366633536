
/**
* Dashboard Gridster style
*/
gridster-item {
    padding-left: 0;
    padding-right: 0;
    .full-height {
      display: block;
      height: 100%;
      & > card {
        display: block;
        height: 100%;
        & > .card {
          display: block;
          height: 100%;
          margin: 0;
        }
      }
    }
  }
    
  .position-highlight .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  
    background-color: rgba(0, 0, 0, 0.1);
  }

.gridster-item-inner {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    .full-height {
      background: #fff;
    }
    lp-card {
      display: block;
      height: 100%;
      .card {
        height: 100%;
        position: relative;
        margin-bottom: 0;
        lp-card-content {
          overflow-y: auto;
        }
      }
    }
  }
  
  .is-dragging .gridster-item-inner {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.1);
    top: -3px;
    bottom: 13px;
  }
  
  .gridster-item-prototype {
    display: block;
    background-color: red;
    position: relative;
    float: left;
    z-index: 99;
    text-align: center;
    font-weight: bold;
    margin: 5px;
    width: 80px;
    height: 60px;
  
  }
  
  .gridster-item-prototype.is-over .gridster-item-inner {
  
    visibility: visible;
  }
  
  .gridster-item-resizable-handler {
    position: absolute;
    z-index: 2;
    display: none;
  }
  
  .gridster-item-resizable-handler.handle-n {
    cursor: n-resize;
    height: 10px;
    right: 0;
    top: 0;
    left: 0;
  }
  
  .gridster-item-resizable-handler.handle-e {
    cursor: e-resize;
    width: 10px;
    bottom: 0;
    right: 0;
    top: 0;
  }
  
  .gridster-item-resizable-handler.handle-s {
    cursor: s-resize;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .gridster-item-resizable-handler.handle-w {
    cursor: w-resize;
    width: 10px;
    left: 0;
    top: 0;
    bottom: 0;
  }
  
  .gridster-item-resizable-handler.handle-ne {
    cursor: ne-resize;
    width: 10px;
    height: 10px;
    right: 0;
    top: 0;
  }
  
  .gridster-item-resizable-handler.handle-nw {
    cursor: nw-resize;
    width: 10px;
    height: 10px;
    left: 0;
    top: 0;
  }
  
  .gridster-item-resizable-handler.handle-se {
    cursor: se-resize;
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent;
  }
  
  .gridster-item-resizable-handler.handle-sw {
    cursor: sw-resize;
    width: 10px;
    height: 10px;
    left: 0;
    bottom: 0;
  }
  
  :host(:hover) .gridster-item-resizable-handler.handle-se {
    border-color: transparent transparent #ccc
  }

  .card-header.card-header-large {
    height: 72px;
  }

  /***card-header-size**/
.card-header.card-header-default {
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 0.5rem;
}

.card-header.left.card-header-default {
  display: initial;
  justify-content: initial;
  align-items: initial;
  padding: 0 0.5rem;
}

.card-header.card-header-large {
  height: 100px;
}

.card-header.left .card-icon-right {
  float: left;
}

.card-header.left {
  text-align: right;
}

.card-header.left.card-header-large > strong {
  line-height: 75px;
}

.card-header.left.card-header-default > strong {
  line-height: 40px;
}

.gridster-item-inner .full-height {
  background: none;
}
 

.card-accent-primary {
	border-top: 2px solid blue!important
}

.card-accent-secondary {
	border-top: 2px solid #4c4f54!important
}

.card-accent-success {
	border-top: 2px solid #45a164!important
}

.card-accent-info {
	border-top: 2px solid #4799eb!important
}

.card-accent-warning {
  border-top: 2px solid #e1a82d!important
}

.card-accent-danger {
	border-top: 2px solid #d16767!important
}


  
  