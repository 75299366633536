.memos {  
    .datatable-body-row {
      i.icon {
        margin-top: 0px;
        font-size: 1em;
      }
    }
  
    margin-bottom: 0 !important;
  
    datatable-header-cell {
      .datatable-header-cell-template-wrap {
        text-align: center;
      }
    } 
  
    datatable-body-cell {
      position: relative;
      cursor: pointer;
      padding: 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  
      .current-memo {
        color: $primary;
        font-weight: bold;
      }
  
      .table-icon {
        position: absolute;
        @include vertical-align-middle();
        height: 100%;
        width: 100%;
        top: 5px;
        left: 0;
        text-align: center;
        font-size: 2em;
        overflow: hidden;
  
        i {
          height: 37px;
        }
      }

      div {
        min-height: 47px !important;        
        justify-content: start !important;    

        &:hover   {
          color: $primary;
        }
      }
    }

    .pager {
      li {
        &.disabled {
          opacity: 0.5;
        }
      }
    }
}

.link-add-memo {
    cursor: pointer;
    padding-top: 10px;
  
    i {
      font-size: 1.5em;
      margin-top: -3px;
      vertical-align: top;
      display: inline-block;
      margin-right: 5px;
    }
}

.memo-delete-confirm {
    margin-top: 12%;
}

lp-memo lp-fields {
  max-width: 100% !important;
}