
lp-home {  
    height: calc(100vh - 55px);
    width: 100%;
    display: block;
    overflow: auto;

  
    #home-container {
      height: 100%;
      width: 100%;
    }
  }