.bullet {
    display: block;
    margin: 0 auto 5px;
    width: 16px;
    height:16px;
    background-color: $lightdark-alt;
    @include border-radius(20px);
    
    &.square {
      @include border-radius(0);
    }
    
    &.green {
      background-color: #10c469;
    }
    
    &.blue {
      background-color: #35b8e0;
    }
    
    &.yellow {
      background-color: #f9c851;
    }
    
    &.orange {
      background-color: #ff5b5b;
    }
    
    &.purple {
      background-color: #5b69bc;
    }
    
    &.dark-blue {
      background-color: #3f51b5;
    }
    
    &.pink {
      background-color: #ff8acc;
    }
    
    &.red {
      background-color: #ff2626;
    }
    
    &.dark-grey {
      background-color: #444444;
    }
    
    &.grey {
      background-color: #98a6ad;
    }
    
    &.light-grey {
      background-color: #eeeeee;
    }
    
    &.black {
      background-color: $black;
    }
}
/* curseur */ 
.cursor-zoom {
  cursor: zoom-in;
}

.cursor-pointer {
  cursor: pointer;
}
/* fin curseur */ 

.clearfix {
  clear: both;
}

.overflow-hidden {
  overflow: hidden;
}


.hide {
  display: none;
}

.no-height {
  height: auto !important;
}

.no-border {
  border: none !important;
}

/* formulaire */
form {
  position: relative;
}

#formulaire {
  background: darken($white, 13%);
}

fieldset {
  margin-top: 12px;
  border: 1px solid $lightgray2;
  padding: 0;
  @include border-radius(4px);
  width: 100%;
  margin-bottom: 12px;
  legend {
    display: inline-block;
    width: auto !important;
    padding-right: 10px;
    padding-left: 5px;
    font-style: italic;
    font-size: 0.9em;
    width: 100%;
    margin-left: 12px;
  }
}

label {
  font-weight: 500;
  padding-top: 7px;
  font-size: $base-font-size;
  font-weight: normal;
  line-height: $base-line-height;
}

.input-group {
  .btn-right {
    position: absolute;
    top: 2px;
    right: -26px;
    .btn-lp-form {
      display: inline-block;
      height: 34px;
      width: 22px;
      text-align: center;
      line-height: 34px;
      font-size: 1.3em;
      cursor: pointer;
      @include border-radius(4px);
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      &.available {
        color: $red !important;
      }
    }
  }
}

.input-group-addon {
  @include border-radius(2px);
  border: 0;
  background-color: rgba(255, 255, 255, 0.6);
  color: $lightdark5;
  i {
    vertical-align: middle;
  }
}

.input-group-btn {
  .btn {
    padding: 8px 12px;
  }
  .btn-sm {
    padding: 5px 10px;
  }
  .btn-lg {
    padding: 10px 17px;
  }
}

textarea.form-control {
  min-height: 90px;
}

.input-group-addon {
  background-color: lighten($muted, 25%) !important;
}

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  @include border-radius(6px);
}

.input-sm {
  min-height: 30px;
  padding: 0px 10px;
  font-size: 12px;
  line-height: 1.5;
  @include border-radius(3px);
  label {
    padding: 0;
  }
}

select[multiple] {
  height: auto;
}

.btn {
  cursor: pointer;
  white-space: nowrap;

  &.btn-large {
    min-width: 150px;
  }
  &.btn-danger,
  &.btn-warning,
  &.btn-secondary,
  &.btn-info {
    color: $white !important;
  }
}
/* fin formulaire */

/* Supprimer le style d'un bouton */
.transparent-btn {
  background: transparent;
  border: 0;
  font-size: 3em;
  line-height: 35px;
  height: 35px;
  overflow: hidden;
  cursor: pointer;

  [class*=" vega-"], [class^=vega-] {
    font-size: 0.9em;
  }

  &:hover {
    color: $primary;
  }
}

/* Card Box */
.card-box {
  padding: 10px 0; 
  @include border-radius(5px);
  margin-bottom: 20px; 
  .card-drop {
    color: $muted;
    font-size: 20px;
    height: 20px;
    line-height: 1px;
    display: inline-block;
  }
}