// Couleur principal
$primary:  #008182;

// Couleurs
$gradientdark:  #0e5d64;
$gradientlight:  #4b9c89;

$activeHover : #d4ede9;
$backgroundline: #eaf9f6;

$border-radius : 25px !important;


// Nom de la classe du thème
.lp-irium-2k23-theme {
    .body-theme {
        &:not(.not-connected) {            
            padding-left: 0;
            background: #fbfbfb;

            @import "core";
            @import "core";
            @import "components"; 
        }

        @import "login";
    }  

    snack-bar-container {
        border-radius: 50px;
        max-width: 100vw;

        button {
            border-radius: 25px;
        }
    }

    // Zoom 
    lp-zoom {
        .card-header {
            background-color: transparent;
            border: transparent;
            -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(5px);
        }
    }

    .backdropScheduler  + .cdk-global-overlay-wrapper .cdk-overlay-pane {
        height: auto !important;
        min-height: auto !important;
    }

    
    mat-dialog-container {
        padding: 10px !important;
        border-radius: $border-radius;
        -webkit-backdrop-filter: blur(15px);
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
        box-shadow: -1px 0px 15px 1px #386566;

        .modal-header {
            .modal-title {                    
                font-weight: 400;
            }
            
            .btn {
                border-radius: 50%;
            }
        }

        .modalError .titleError,
        .modalWarning .titleError,
        .modalAlert .titleError {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        

        lp-modal-eval .titleModal, lp-modal-change .titleModal, lp-modal-confirm .titleModal, lp-modal-tarif .titleModal {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        lp-zoom div > .card {
            border: 0;            
            background: transparent;

            .card-header {
                background: transparent;
                backdrop-filter: none;
            }

            .modal-body {
                border-top: 1px solid #ccc;
            }
        }   
        
        &#modalWithHeader {
            padding: 0 !important;
            
            .titleError {
                padding: 20px 40px 20px 60px;
            }
        }
        .modal-planning { 
            #modalPlanning {
                .modal-body {
                    padding-top: 0px !important;

                    .scheduler-config-btn {
                        top: -62px;
                    }
                }

                .modal-header {
                    h5 {
                        line-height: 42px;
                    }
                }
              .scheduler {
                padding: 0 !important;
                margin: 0 !important;

                lp-scheduler .dhx_cal_container {
                    background: transparent;
                }
              }
            }
        }
    }

    .cdk-overlay-container .darkBackdropClass, .cdk-overlay-container .zoomBackdropClass {
        background: rgba(255, 255, 255, 0.5);
    }

    .col-lg-10.col-md-7 {
        width: 100% !important; 
    }

    .btn-actions {
        display: flex;
        justify-content: center;

        .btn {                    
            border-radius: 30px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .modalCloseBtn {
        border-radius: 30px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 !important
    }
    
    .update-snackbar {
        margin: 20px 30px;
        border-radius: 15px;

        > div {
            border-radius: 15px;
            max-width: 50vw;
        }
    }

    .mat-mdc-snack-bar-container {
        --mat-mdc-snack-bar-button-color: inherit;
        --mdc-snackbar-container-color: inherit;
        --mdc-snackbar-supporting-text-color: inherit;
    }       

    /* mainzoom */
    @import "mainzoom.scss";     
}



/* Bouton de changement de thème */
.theme {
    // Classe à changer
    &.irium-2k23 {
        background-color: $background !important;
        background: linear-gradient(to right bottom, $gradientdark 0% 46%, $background 52%, $gradientlight 54%, $gradientlight 100%) !important;   
        /* width */
    }
}