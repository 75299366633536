// fond noir
.overlay {
    display: flex;
  
    > div {
      @include vertical-align-middle();
    }
}

.modal {
    .modal-dialog {
      .modal-content {
        border-color: darken($white, 13%);
        @include border-radius(2px);
        @include box-shadow(none);
        padding: 25px;
        .modal-header {
          border-bottom-width: 2px;
          margin: 0;
          padding: 0;
          padding-bottom: 15px;
        }
        .modal-body {
          padding: 20px 0;
        }
        .modal-footer {
          padding: 0;
          padding-top: 15px;
        }
      }
    }
  }
  
  .modal-full {
    width: 98%;
  }
  
  .modal-content {
    .nav.nav-tabs+.tab-content {
      margin-bottom: 0px;
    }
    .panel-group {
      margin-bottom: 0px;
    }
    .panel {
      border-top: none;
    }
  }
  
  /* Custom-modal */
  
  .modal-demo {
    background-color: $white;
    width: 600px;
    @include border-radius(4px);
    display: none;
    .close {
      position: absolute;
      top: 15px;
      right: 25px;
      color: $light;
    }
  }
  
  .custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: $muted;
    color: $white;
    text-align: left;
    margin: 0px;
  }
  
  .custom-modal-text {
    padding: 20px;
  }
  
  .custombox-modal-flash,
  .custombox-modal-rotatedown {
    .close {
      top: 20px;
      z-index: 9999;
    }
  }
  
.modal-locpro-zoom {
    max-width: 90%;
    max-height: 100vh;
    overflow: hidden;

    .nsm-body {
      max-height: 90vh;
    }
}


/********************** MODAL ***************************/
.modal-workflow {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
    max-height: 90%;
  
    .memo-list-tab {
      height: 100%;
    }
    .transparent-btn {
      background: transparent;
      border: 0;
      font-size: 3em;
      line-height: 1em;
      height: 31px;
      cursor: pointer;
  
      &:hover {
        color: $primary;
      }
    }
   
  }
  
  .modal-workflows,
  .modal-big {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
    display: flex;
    max-height: 100%;
  
    .nsm-content {
        max-height: 90%;
        margin: 0;
        padding: 0;    
    }

    .memo-content {
      overflow: auto;
    }
  
    .memo-list-tab {
      height: 100%;
    }  
    
    .modal-body {
      overflow-x: auto;
    }
  }
  
  .modal-workflows {
    width: 52%;
    min-width: 52%;
    max-width: 52%;
    max-height: 100%;
  }
  
  .modal-actions {
    width: 900px;
    min-width: 900px;
    max-width: 900px;
    display: flex;
    max-height: 90%;
  
    &.modal-confirm-actions {
      width: 600px;
      min-width: 600px;
      max-width: 600px;

    }

    .modal-body {
      overflow-y: auto;
    }
    
  }

.modalWarning {
  min-width: 300px;  

  padding: 0;
  @include border-radius(4px);

  .titleError {
    background: $vega-orange;
    color:$white;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
    padding: 15px 40px 15px 60px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px; 
    position: relative;  
    font-weight: bold;
  }

  button {
    border: 0;
  }
} 

.modalError, 
.modalAlert {  
  width: auto;
  min-width: 300px;
  max-width: 90vw; 

  padding: 0;
  @include border-radius(4px);

  .titleError {
    background: $red;
    color:$white;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
    padding: 15px 40px 15px 60px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px; 
    position: relative;  
    font-weight: bold;
  }

  button {
    border: 0;
  }
} 

lp-modal-eval,
lp-modal-change, lp-modal-confirm, lp-modal-tarif {
  width: 300px;  

  padding: 0;
  @include border-radius(4px);
  .titleModal {
    background: $light;
    color:$dark;
    padding: 15px 60px 15px 60px;
    font-weight: bold;
  }
}

.mat-dialog-container {
  padding: 0 !important;

  .panel-group {
    width: 100%;
  }
}

.modal-big {
    .nsm-content {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  .nsm-content {
    overflow: auto;  
  
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    
    .nsm-body {
      .modal-header,
      .modal-body {
        padding: 0.5rem;
      }
    }
  }
  

.modal {
  height: auto;
 }
 
 .modal .modal-dialog .modal-content .modal-body {
   padding-bottom: 0;
 
   .alert {
     margin-bottom: 0;
   }
 }
 
 .modal-title {
   font-size: 1.8em;
   font-weight: 300;
 }

 lp-memo,
 lp-workflow,
 lp-modal-trace,
 lp-zoom {
   .nsm-dialog {
     display: block !important;
     height: 90vh;
     margin-top: 5vh;
     
     .nsm-content {
       margin: 0;
       max-height: 100%;
     }
   }
 }
 
.modal-left {
  position: absolute !important;
  left: 6% !important;
}

.modal-right {
  position: absolute !important;
  right: 4% !important;
}

/** Gestion des z-index */
.cdk-overlay-container {
  //z-index: 1081 !important;
  position: static;

  .darkBackdropClass,
  .zoomBackdropClass {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .commonToolsBackdropClass, .commonToolsBackdropClass + .cdk-global-overlay-wrapper {
    z-index: 1040 !important;    
  }

  .darkBackdropClass, .darkBackdropClass + .cdk-global-overlay-wrapper,
  .zoomBackdropClass, .zoomBackdropClass + .cdk-global-overlay-wrapper {
    z-index: 1050 !important;    
  }

  .calendarBackdropClass , .calendarBackdropClass  + .cdk-global-overlay-wrapper {
    z-index: 1060 !important;    
  }
  

  // Message de confirmation / Message d'erreur / Modal Change / Message important => au devant de tout   
  .alertBackdropClass, .alertBackdropClass + .cdk-global-overlay-wrapper {
    z-index: 1090 !important;  
  }

  .zoomBackdropClass + .cdk-global-overlay-wrapper {
    align-items: flex-start !important;
    
    .cdk-overlay-pane {
      margin-top: 10vh;
      max-height: 80vh;

      .panel-primary {
        margin-bottom: 0;
      }
    }
  }
}

.ediTableSettings {
  font-size : 28px;
  vertical-align: middle;
  cursor: pointer;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  height: 100vh !important;
  z-index: unset !important;
  position: absolute !important;
}

/* Spooler modal */
lp-modal-promise-spooler-preview {
  .modal-body,
  iframe {
    height: 100%;
  }
}

lp-modal-workflow-list {
  lp-cell {
    cursor: pointer;
  }
}