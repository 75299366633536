// Couleur principal
$primary:  #073637;
$secondary:  #ce0538;

// couleur de fond
$background: rgb(255, 255, 255);

// Couleurs d'écritures;
$fontColor: #535353;
$inputColor: rgb(56, 56, 56);

@import "logo";

// Nom de la classe du thème
.lp-iMobCheckPlus-theme {
    .body-theme {
        &:not(.not-connected) {            
            padding-left: 0;
            background: #fbfbfb;

            @import "core";
            @import "components"; 
        }
 
        &.not-connected {
            background: linear-gradient(135deg, #073637db 0%, #073637d6 100%), $bg-login center center no-repeat;    
            background-size: cover;

            .logos {
                background: $primary-logo top center no-repeat;
                height: 150px;
                background-size: contain;
            }

            .irium-link {
                .text-primary {
                    color: #fff !important;
                }
            }

                        
            lp-login .login-card {
                background: rgba(255, 255, 255, 0.85);

                .form-group {
                    border-bottom: 2px solid rgba(0, 0, 0, 0.13);

                    &:focus-within {
                        border-color: rgba($primary, 0.75);
                    }
                    
                    input {
                        border: 0;
                        border-radius: 0;
                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .input-group-addon, .input-group-addon button {
                        background: transparent;
                    }

                }
            }
        }
    }  

    

    $dark-primary-text: rgba(black, 0.65);
    $dark-secondary-text: rgba(black, 0.50);
    $dark-disabled-text: rgba(black, 0.38);
    $dark-dividers: rgba(black, 0.12);
    $dark-focused: rgba(black, 0.12);
    $light-primary-text: white;
    $light-secondary-text: rgba(white, 0.7);
    $light-disabled-text: rgba(white, 0.5);
    $light-dividers: rgba(white, 0.12);
    $light-focused: rgba(white, 0.12);
    
    $irium-palette: (
      50: lighten($secondary, 35%),
      100: lighten($secondary, 30%),
      200: lighten($secondary, 25%),
      300: lighten($secondary, 20%),
      400: lighten($secondary, 15%),
      500: lighten($secondary, 10%),
      600: lighten($secondary, 5%),
      700: $secondary,
      800: darken($secondary, 5%),
      900: darken($secondary, 10%),
      A100: lighten($secondary, 15%),
      A200: lighten($secondary, 5%),
      A400: $secondary,
      A700: darken($secondary, 5%),
      contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $light-primary-text,
      )
    );
    
    $my-app-primary: mat.define-palette($irium-palette);
    $my-app-accent: mat.define-palette($irium-palette, A200, A100, A400);
    $my-app-warn: mat.define-palette(mat.$red-palette);
    
    $my-app-theme: mat.define-light-theme(
      (
        color: (
          primary: $my-app-primary,
          accent: $my-app-accent,
          warn: $my-app-warn,
        ),
      )
    );
    
    @include mat.all-component-themes($my-app-theme);

    snack-bar-container {
        border-radius: 50px;
        max-width: 100vw;

        button {
            border-radius: 25px;
        }
    }

        
    .btn-primary {
        background-color: $secondary !important;
        border-color: $secondary !important;
    
        &:hover {
        background-color: darken($secondary,5%) !important;
        }
    }
    
    // Zoom 
    lp-zoom {
        .card-header {
            background-color: transparent;
            border: transparent;
            -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(5px);
        }
    }

    .backdropScheduler  + .cdk-global-overlay-wrapper .cdk-overlay-pane {
        height: auto !important;
        min-height: auto !important;
    }

    
    mat-dialog-container {
        padding: 10px !important;
        border-radius: $border-radius;
        -webkit-backdrop-filter: blur(15px);
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
        box-shadow: -1px 0px 15px 1px #386566;

        .modal-header {
            .modal-title {                    
                font-weight: 400;
            }
            
            .btn {
                border-radius: 50%;
            }
        }

        .modalError .titleError, .modalAlert .titleError {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
        

        lp-modal-eval .titleModal, lp-modal-change .titleModal, lp-modal-confirm .titleModal, lp-modal-tarif .titleModal {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        lp-zoom div > .card {
            border: 0;            
            background: transparent;

            .card-header {
                background: transparent;
                backdrop-filter: none;
            }

            .modal-body {
                border-top: 1px solid #ccc;
            }
        }   
        
        &#modalWithHeader {
            padding: 0 !important;
            
            .titleError {
                padding: 20px 40px 20px 60px;
            }
        }
        .modal-planning { 
            #modalPlanning {
                .modal-body {
                    padding-top: 0px !important;

                    .scheduler-config-btn {
                        top: -62px;
                    }
                }

                .modal-header {
                    h5 {
                        line-height: 42px;
                    }
                }
              .scheduler {
                padding: 0 !important;
                margin: 0 !important;

                lp-scheduler .dhx_cal_container {
                    background: transparent;
                }
              }
            }
        }
    }

    .cdk-overlay-container .darkBackdropClass, .cdk-overlay-container .zoomBackdropClass {
        background: rgba(255, 255, 255, 0.5);
    }

    .col-lg-10.col-md-7 {
        width: 100% !important; 
    }

    .btn-actions {
        display: flex;
        justify-content: center;

        .btn {                    
            border-radius: 30px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .modalCloseBtn {
        border-radius: 30px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 !important
    }

    /* mainzoom */
    @import "mainzoom.scss";     
}


/* Bouton de changement de thème */
.theme {
    // Classe à changer
    &.iMobCheckPlus-2k23 {
        background-color: $background !important;
        background: linear-gradient(to right bottom, $primary 0%, $primary 46%, #000 52%, $secondary 54%, $secondary 100%) !important;    
    }
}