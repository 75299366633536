h1 {
  background: center center no-repeat $primary-logo;
}

//boutons
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    background-color: lighten($primary, 10%) !important;
    border-color: lighten($primary, 10%) !important;
    @include box-shadow( none !important);
  }
}

.card {
  border: 0;
}

/*.card-box {
    //background-color: darken($dark,10%); 
} */

.container-2-cols {
  >.col-md-6 {
    &:last-child {
      background: url("/assets/img/right-col-background-dark.png") bottom center no-repeat;
      background-color: transparent;
      background-size: contain;
        border-left: 1px solid rgb(54, 54, 54);
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$lightdark-alt+0,000000+100&0.65+0,0+100 */
        background: -moz-linear-gradient(left, $lightdark-alt 0%, rgba(0, 0, 0, 0) 10px);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $black 0%, rgba(0, 0, 0, 0) 10px);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $black 0%, rgba(0, 0, 0, 0) 10px);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$lightdark-alt, endColorstr=$black, GradientType=1);
        /* IE6-9 */
    }
  }
}

h3,
h4 {
  color: $fontColor;
}

.modal-body,
.card {
  color: $fontColor;
}

.table-striped {
  background: darken($background, 5%) !important;
  tr:nth-child(odd) {
    background-color: darken($background, 15%) !important;
  }
  tr:nth-child(even) {
    background-color: darken($background, 5%) !important;
  }
}
