.formInspection {
    .inspection-memo {
      font-size: 2em;    
      height: 32px;
      display: inline-block;
      cursor: pointer;
      
      overflow: hidden;

      &.available {
        color: $red !important;
      }
    }
  }
  
  .list-damage {
    .available {
      color: $red !important;
    }
    
.awaiting-backup {
  &:hover, &:focus {
    color: $lightdark7 !important;

    i.icon {
      cursor: default !important;
    }
  }
}
  
    .datatable-row-group {
      .datatable-body-cell:first-child {
        > div {
          position: relative;
        
          .rowNumber-list {  
            position: absolute;
            left: 0;
            top: 4px;
            width: 40px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  
.img-list-damage {
    margin-left: 3px; 
  }
  
  .activeSlide .datatable-row-group {
    background: rgba($primary, 0.2) !important;
  
    .rowNumber-list { 
      cursor: default;
    }
  }

.previewTempImg {
    max-width: 100%;
    max-height: 115px;
    cursor: zoom-in;
}
