
/**************
* ALERTE
**************/
.alert {
    border-width: 2px;
    .btn {
      margin-top: 10px;
    }
  }
  
  .alert-success {
    background-color: rgba(95, 190, 170, 0.3);
    border-color: rgba(95, 190, 170, 0.4);
    color: #5fbeaa;
  }
  
  .alert-info {
    background-color: rgba(52, 211, 235, 0.2);
    border-color: rgba(52, 211, 235, 0.3);
    color: $info;
  }
  
  .alert-warning {
    background-color: lighten($warning, 10%);
    border-color: $warning;
    //color: $white;
  }
  
  .alert-danger {
    border-color: $danger;
    color: $white;
    padding-left: 50px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") no-repeat left 10px top 11px lighten($danger, 10%);
  }

  
/**************
* NOTIFICATIONS
**************/
#toast-container {
  .toast-top-center>div {
    margin-top: 10px !important;
  }

  .toast-top-full-width {
    padding-left: 50px;
    padding-top: 15px;
  }
}