
.btn-scheduler-panel-close {  
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 15px;
}


.scheduler-search-date {
  display: inline;
  width: 170px;
  margin: 5px 5px 0px 0px;
}

.scheduler {
  .scheduler-loader {
    background-color: $white;
    height: calc(100vh - 120px) !important;
    width:100%;
    @include border-radius(5px);
    margin-top: 10px;
  }
  *:not(i) {
    font-family: $font-primary;
  }

  lp-scheduler {
    margin-top: 10px;
    width: 100%;
    display: flex;

    .save-configuration,
    .scheduler-config-panel {
      @include scheduler-box-setting();
      

      lp-field-details {

        .title-buttons {
          right: 55px;
        }
        
        .panel {
          border: 0;
        }

        .panel-heading {
          border: 0;
          background: transparent;
          padding-left: 0;
          padding-bottom: 0;

          h5 {                
            cursor: default;
            line-height: 30px;
            font-size: 24px;
          }
        }

        /*.modal-body {
          td {
            padding-left: 0 !important;
          }
        }*/
      }

      .panel-body {
        padding: 5px !important;
      }

      .scheduler-btn {
        margin-top: 10px;
        float: right;
        margin-right:15px;
      }

      .form-control-scheduler {
        float: left;
        margin-left: 15px;
        float: right;
      }

      lp-field-details .container {
        margin-top: 0 !important;
      }
    }


    .dhx_cal_container {
      position: relative;
      overflow: hidden;
      background-color: $white;
      font-family: Arial, san-serif;
      font-size: 12px;
      height: calc(100vh - 120px) !important;
      @include border-radius(5px);

      &:focus {
        outline-style: none !important;
        @include box-shadow(none);
        border: 1px solid darken($white, 17%);
      }

      .dhx_cal_header {
        border-left: 0px solid transparent;
        border-top: 0;
        height: 30px !important;
        top: 23px !important;
        border-bottom: 1px solid darken($white, 19%);

        .dhx_scale_bar {
          border-top: 1px solid darken($white, 19%);
          height: 28px !important;
          line-height: 24px;
        }
      }

      .dhx_cal_data {
        border-top: none;

        >table {
          border-top: 1px solid darken($white, 19%);
          border-left: 1px solid darken($white, 19%);

          >tbody {
            >tr {
              >td:first-child {
                padding-left: 3px;
              }
            }
          }
        }

        .dhx_matrix_scell {
          &:hover {
            background: $lightdark;
          }

          >div {
            text-align: left;
            float: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
      }

      .dhx_cal_navline {        
        width: auto !important;
        height: auto!important;
        left: 11px !important;
        top: 19px !important;

        > * {
          position: static;
          display: inline-block;
          vertical-align: middle;  
        }

        .dhx_cal_prev_button,
        .dhx_cal_next_button {
          background-color: $white;
          height: 22px;
          width: 20px;

          &:hover {
            background-color: $lightdark;
          }
        }
        
        .dhx_cal_prev_button {
          border-right-width: 0;
        }

        .dhx_cal_next_button {
          border-left-width: 0;
        }

        .scheduler-search-date {
          width: 110px;
          font-size: 1.1em;
          margin: 0;
          padding: 0 0 0 2px;
          @include border-radius(0);
          height: 24px; 

          &:hover {
            background-color: $lightdark;
            cursor: pointer !important;
          }

          &:focus {     
            outline: 0 !important;        
            @include box-shadow(none);
            border: 1px solid $primary !important;
            background-color: $white;
            color: $dark;
          }
        }

        .scale-btn-scheduler {
          margin-left: 5px;
          font-size: 1.1em;
          height: 24px;

          * {
              font-size: 1em;
              top: 0;
          }
          button {
            padding: 1px 5px 2px 10px;

            &:after {
                vertical-align: middle;
            }
          }
        }

        .scheduler-custome-control {
          .dhx_cal_date {
            color: $dark;
            font-size: 1em;
            width: auto;
            position: static;
            margin-right: 25px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .scheduler-config-btn {
    position: fixed;
    top: 53px;
    color: white;
    font-size: 25px;
    right: 65px;
    z-index: 3;    
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}

/* Lightbox - scheduler */
.dhx_cal_light {
  @include border-radius(2px);
  @include box-shadow(none);
  padding: 25px;
  width: 400px;
  font-size: 1em;
  height: 215px !important;

  .dhx_cal_ltitle {
    border-bottom: 2px solid darken($white, 8%);
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
    line-height: auto;
    height: auto;

    * {
      font-size: 1.8em;
      font-weight: 300;
      font-family: $font-primary;
    }
  }

  .dhx_cal_larea {
    width: 100%;
  }
}
input[type="date"]::-webkit-inner-spin-button {
    width: none;
    -webkit-appearance: none;
}

.active-filter-container {
  font-size: smaller;
  vertical-align: middle !important;
  margin-bottom: 2px !important;

  >li {
    border: 1px solid $white;
    cursor: pointer;
    position: relative;
    display: inline-block;
    top: 0 !important;

    i {
        position: absolute;
        right: 5px;
        top: calc(50% - 9px);
        color: $dark;
        font-size: 1.3em;
        height: auto;
        margin: 0 !important;
    }

    &:before {
      position: absolute;
      content: "";
      display: block;
      height: 100%;
      width: 0rem;
      background-color: $white;
      top: 0;
      right: 0;
      @include transition(width, 0.2s, ease);
    }

    &:hover,
    &:focus {
      &:before {
        width: 1.5rem;
      }
    }
  }

  .active-filter {
    margin-right: 10px;
  }
}

.refresh{
  margin-right : 110px;
}

.planning-btn {
  padding-top: 0;
  margin-left: 5px;
  height: 50px;
  position: fixed;
  z-index: 5;
  right: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    line-height: 0.8em;
    font-size: 2em;
    cursor: pointer;
    color: white;
    margin: 5px 0 0 00 !important;
  }
}

.reset-filter {
  &:hover {
    cursor: pointer;
  }
}

.body-scheduler {
  position: relative;
}

.filter-tab {
  @include scheduler-box-setting();

  .filter-panel {

    .row {
      width: 100%;
    }
  }
    
  lp-ui-accordion {
    margin-left: 0;
  }
  
}



/* switch scheduler */
.dhx_cal_larea {
  height: auto !important;
}

.dhx_wrap_section  {
  padding: 20px 0 !important;
  border-bottom: 0 !important;
  
  input[type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  .dhx_cal_lsection {    
    padding: 0;
    margin-top: 1px;
    text-align: left;
    color: $dark;
  }

  .dhx_cal_radio {
 
    /* For callout panels in foundation
    */
    padding: 0 !important;    
    position: relative;
    height: auto !important;
    @include border-radius(4px);
  
    input {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  
    input + label {
      position: relative;
      z-index: 2;
      display: block;
      float: left;
      padding: 3px 0 4px 0;
      margin: 0;
      top: 0;
      line-height: 26px !important;
      text-align: center;
    }
  
    a {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      z-index: 1;
      width: 10px;
      height: 100%;
      display: block;
      @include transition(all, 0.2s, ease-out);
    }
  
    /* 2 items
     */
    label:nth-child(2):nth-last-child(4),
    label:nth-child(2):nth-last-child(4) ~ label,
    label:nth-child(2):nth-last-child(4) ~ a {
      width: 50%;
    }
  
    label:nth-child(2):nth-last-child(4) ~ input:checked:nth-child(3) + label ~ a {
      left: 50%;
    }
  
    /* 3 items
     */
    label:nth-child(2):nth-last-child(6),
    label:nth-child(2):nth-last-child(6) ~ label,
    label:nth-child(2):nth-last-child(6) ~ a {
      width: 33.33%;
    }
  
    label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(3) + label ~ a {
      left: 33.33%;
    }
  
    label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(5) + label ~ a {
      left: 66.66%;
    }
  
    /* 4 items
     */
    label:nth-child(2):nth-last-child(8),
    label:nth-child(2):nth-last-child(8) ~ label,
    label:nth-child(2):nth-last-child(8) ~ a {
      width: 25%;
    }
  
    label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(3) + label ~ a {
      left: 25%;
    }
  
    label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(5) + label ~ a {
      left: 50%;
    }
  
    label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(7) + label ~ a {
      left: 75%;
    }
  
    /* 5 items
     */
    label:nth-child(2):nth-last-child(10),
    label:nth-child(2):nth-last-child(10) ~ label,
    label:nth-child(2):nth-last-child(10) ~ a {
      width: 20%;
    }
  
    label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(3) + label ~ a {
      left: 20%;
    }
  
    label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(5) + label ~ a {
      left: 40%;
    }
  
    label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(7) + label ~ a {
      left: 60%;
    }
  
    label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(9) + label ~ a {
      left: 80%;
    }
  
    /* 6 items
     */
    label:nth-child(2):nth-last-child(12),
    label:nth-child(2):nth-last-child(12) ~ label,
    label:nth-child(2):nth-last-child(12) ~ a {
      width: 16.6%;
    }
  
    label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(3) + label ~ a {
      left: 16.6%;
    }
  
    label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(5) + label ~ a {
      left: 33.2%;
    }
  
    label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(7) + label ~ a {
      left: 49.8%;
    }
  
    label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(9) + label ~ a {
      left: 66.4%;
    }
  
    label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(11) + label ~ a {
      left: 83%;
    }
  
  }  
}

.modal-planning {
  display: flex;  

  #modalPlanning {
    width: 100%;
    position: relative;
  }

  .title-buttons {
    position: absolute;
    right: 55px;
    top: 3px;
  }

  .nsm-content {
    padding: 0;
    margin: 0;

    .modal-dialog {
      max-width: 100%;
    }
  }

  .modal-body {
    .scheduler-config-btn {
      top: -55px;
      right: 114px;
      position: absolute;
    }

    .scheduler lp-scheduler .scheduler-config-panel , 
    .filter-tab {
      height: calc(100vh - 95px);
    }
    .scheduler lp-scheduler .dhx_cal_container {
      height: calc(90vh - 105px) !important;
    }
  }

  .modal-planning-header {  
    background: $dark;
    @include border-radius(0);
    color: $white;

    .scheduler-right-button {
      top: 3px;
      right: 68px;
      position: absolute;
    }
  }
}


/******  scheduler colors *****/
.odd {
  background-color: $scheduler-odd !important;
}

.white {
  background-color: $scheduler-white !important;
}

.light-grey {
  background-color: $scheduler-light-grey !important;
}

.grey {
  background-color: $scheduler-grey !important;
}

.black {
  background-color: $scheduler-black !important;
}

.light-green {
  background-color: $scheduler-light-green !important;
}

.green {
  background-color: $scheduler-green !important;
}

.light-yellow {
  background-color: $scheduler-light-grey !important;
}

.yellow {
  background-color: $scheduler-yellow !important;
}

.light-orange {
  background-color: $scheduler-light-orange !important;
}

.orange {
  background-color: $scheduler-orange !important;
}

.light-red {
  background-color: $scheduler-light-red !important;
}

.red {
  background-color: $scheduler-red !important;
}

.light-blue {
  background-color: $scheduler-light-blue !important;
}

.blue {
  background-color: $scheduler-blue !important;
}

.theme-color {
  background-color: $scheduler-theme-color !important;
}

.scheduler-right-button {
  &.active {
    i {
      color: lighten($primary, 15);
    }
  }
}

.scheduler-details-icons {
  .scheduler-details-icon {
    font-size: 2em;
    color: $dark;
    display: inline-block;
    height: 35px;

    .scheduler-details-icon-link {
      color: $dark;

      &:hover {
        color: $primary;
        cursor: pointer;
      }
    }
  }
}

.scheduler-scale-dropdown{  
  select {    
    height: 24px !important;
    padding: 0;
  }
}

.notFound{
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  color: red;
}

.schedulerSearchDate {
  float: none;
  width: 110px;

  > span {
    > div {
      height: 24px;
      position: static;

      input {
        height: 100%;
        padding-left: 2px;
        padding-right: 26px;        
        font-size: 1.2em;
        border-radius: 0 !important;
      }

      .input-group-btn {
        width: 24px;

        i {
          margin-top: 0;
          font-size: 1.2em;
        }
      }
    }
  }
}

.dhx_timeline_data_cell.today {
  background-color: $danger;
}
