ngx-datatable {
  background: #fff;

  .mat-slide-toggle-label {
    overflow: hidden;    
    margin-bottom: 0;
    padding-top: 0;
  }
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  line-height: 1.1;
  overflow-y: hidden !important;
} 
.ngx-datatable .datatable-body .datatable-scroll {
  display: block !important;
}
.barre{
  text-decoration: line-through;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 35px);
    height: 100%;
    content: "";
    z-index: 5;
    background: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 10px,
      #f00 10px,
      #f00 20px
    );
    opacity: 0.25;
  }
}
/* editable table */
.datatable-editable {
    tr {
      &:hover {
        select {
          background-color: $lightdark;
        }
      }
    }
  
    thead {
      .actions {
        width: 50px;
        text-align: center;
        padding: 0;
      }
    }
  
    .fa-trash-o {
      color: $danger;
    }
  
    .fa-times {
      color: $danger;
    }
  
    .fa-pencil {
      color: $success;
    }
  
    .fa-save {
      color: $success;
    }
  
    .action {
      vertical-align: middle;
      text-align: center;
      padding: 0;
    }
  
    tbody {
      td {
        border: 1px solid darken($lightdark, 5%);
        padding: 0;
  
        input.form-control {
          width: 100%;
          border: 0;
          line-height: 2;
          @include border-radius(0);
  
          &:hover,
          &:focus {
            @include box-shadow(none);
          }
        }
  
        select {
          border: 0;
          margin-top: 3px;
          @include transition(none, 0s);
          font-size: 1rem;
  
          &:hover {
            background-color: $lightdark;
          }
        }
        lp-date-moth,
        lp-date {
          border: 0;
          padding: 0;
          margin-top: 4px;
          @include transition(none, 0s);
  
          input {
            font-size: 1rem;
            @include transition(none, 0s);
          }
  
          button.btn-primary {
            background: transparent !important;
  
            i {
              color: $muted !important;
  
              &:hover {
                color: $primary !important;
              }
            }
          }
        }
      }
    }
}

/* Responsive data table */
table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child:before {
  @include box-shadow(0 0 3px fade($dark, 20%));
  background-color: $primary;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
  background-color: $danger;
}

/* Key Table */
table.dataTable th.focus,
table.dataTable td.focus {
  outline: 3px solid $custom !important;
  outline-offset: -1px;
}

.ngx-datatable.material {  
  @include box-shadow(none !important);
  border: 1px solid darken($lightdark, 5%);
  margin-bottom: 20px;

  .datatable-header-inner {
    .datatable-header-cell {
      font-size: 1.2em;
      font-weight: 400;
      color: $dark;
    }
  }

  &:not(.cell-selection) .datatable-body-row {
    border-bottom: 1px solid darken($lightdark, 5%);
  }

  &:not(.cell-selection) .datatable-body-row:hover {
    .datatable-row-group {
      background-color: $lightgray2;
    }
  }

  .footer-table {
      background-color: darken($lightgray2, 5%) !important;
      * {
        background-color: transparent !important;
      }
  }

  .empty-row {
    height: auto;
  }
}

datatable-row-wrapper:nth-child(odd) .datatable-row-group {
  background-color: $lightdark;
}

// FIX (v.11.2.0): Problème de largeur de colonnes
.ngx-datatable .datatable-body .datatable-body-row>div {
  width: auto !important;
}

.datatable-body-row {

  i.icon {
    height: 35px;
    display: inline-block;
    font-size: 28px;
    vertical-align: top;
    margin-top: -3px;
    cursor: pointer;    
    overflow: hidden;

    &[class^="vega-"],
    &[class*=" vega-"],
    &.fa-long-arrow-right {
      padding-top: 5px;

      &.vega-openingHours {
        padding-top: 0px;
      }
    }
  }
}

.table-height-35 {
    datatable-body-cell {
      display: flex !important;
      align-items: center;
      justify-content: left;
      padding: 0 0px !important;
  
      > div {
        width: 100%;
      }
  
      .taxDisplay {
        display: inline-block;
        width: 30px;
        text-align: center;
      }
    }
  
    &.locprozoom {
       
      .datatable-header-inner,
      .datatable-header-inner > .datatable-row-center,
      datatable-scroller,
      datatable-body-row, 
      datatable-body-row > .datatable-row-center {
        width: 100% !important;
      }
      .nowidth {
        flex-grow: 1;
        width: auto !important;
        min-width: 100px !important;
      }
    }
  
    datatable-header-cell {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
}

ngx-datatable {
    border: 1px solid #BDB1B1 !important;  

    .input-group-addon {
      display: none;
    }

    &.loading-table {
      datatable-body {
        min-height: 5px;
      } 
    }
  
    datatable-body {
      max-width: 100%;
    }

    datatable-header {
      max-width: 100%;
      background: $primary;

      * {
        background: transparent !important;
      }
  
      .datatable-header-inner {
        height: 100%;
  
        datatable-header-cell {
          border-left: 1px solid $white; 
          display: flex !important;
          padding: 0 !important;
          align-items: center;
        
          &:first-child {
            border-left-width: 0;
          }

          &.sortable {
            .datatable-header-cell-template-wrap {
              width: 100%;
              
              .datatable-header-cell-wrapper, .datatable-header-cell-wrapper span {
                display: block;
                width: 100%;
              }
            }
          }
  
  
  
          .datatable-header-cell-wrapper {     
            padding: 0 2px !important;
            color: $white;
            padding-left: 5px;
            font-size: 0.9em;
            font-style: italic;
          }

          &.sortable {
            .sort-btn {
              position: absolute;
              right: 5px;
              top: calc(50% - 8px);
              color:$white;
  
              &:before {
                opacity: 0.5;
                font: normal normal normal 14px/1 FontAwesome;
                content: "\f0dc";
              }  
  
              &.sort-asc:before {
                color:$white;
                opacity: 1;
                content: "\f0de";
              }
              
              &.sort-desc:before {
                opacity: 1;
                content: "\f0dd";
              }
            }
          }
        }
      }
    }
  
    datatable-row-wrapper {
  
      &:nth-child(odd) .datatable-row-group {
        background-color: #f9f9f9;
      }
      
      datatable-body-row {
        border: 0;
  
        datatable-body-cell {
          border-left: 1px solid lighten($muted, 19%);  
          border-bottom: 1px solid lighten($muted, 19%);  
  
          .datatable-body-cell-label {
            padding: -50px;
            min-height: 100%;
            display: flex;
            align-items: stretch;  
  
            > div,
            > lp-cell {
              display: flex;
              flex: 0 0 100%;
              min-height: 100%;
              flex-direction: column;
              justify-content: center;
            }  

            lp-cell {
              padding: 2px;
              
              > div {
                overflow: hidden;
              }
            }     
            
            lp-duplicate {
                width: 35px;
                
                a {
                  width: 100%;
                  display: block;
                  text-align: center;

                  i {
                    margin-left: 0 !important;
                  }
                }
            }

            lp-remove {
              width: 35px;
              
              a {
                width: 100%;
                display: block;
                text-align: center;

                i {
                  margin-left: 0 !important;
                }
              }
          }

          lp-save {
            width: 35px;
            
            a {
              width: 100%;
              display: block;
              text-align: center;

              i {
                margin-left: 0 !important;
              }
            }
        }
          }
        
          &:first-child {
            border-left-width: 0;
          }
        }
      }
  
      &:last-child {      
        datatable-body-cell {
          border-bottom: 0;
      }
    }
  
  }

  lp-footer {
    width: 100%;
  }
  .progress-linear {
    z-index:1;

    .container {
      background-color: rgba($primary, 25%) !important;      
      .bar {
        background-color: $primary !important;
      }
    }
  }
}
  
datatable-scroller,
datatable-scroller datatable-body-row, 
datatable-scroller datatable-body-row .datatable-row-center {
  min-width: 100%;  
}
.ngx-datatable.material .datatable-header .datatable-header-cell,
datatable-scroller datatable-body-row .datatable-row-center datatable-body-cell {
  border-bottom: 0;
  padding: 0 !important;

  &:last-child {
    border-right: 1px solid #ced5d8;
  }
}

.ngx-datatable.material .datatable-footer .page-count {
  line-height: 40px;
  height: 40px;  
  padding-left: 10px;
  padding-right: 0;  
  display: block;
  width: 75px;
  flex: none;
}

.tableWithInput {
    lp-cell {
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  
.table-details {
    border: 1px solid #BDB1B1; 
    thead {
      background: $primary;
  
      th {
        border-left: 1px solid $white;  
        height: 35px; 
        padding: 0 2px;
        vertical-align: middle;
        color: $white;
        padding-left: 5px;
        font-size: 1.1em;
        font-style: italic;
  
    
        &:first-child {
          border-left-width: 0;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #BDB1B1 ;  
  
        &:nth-child(odd) {
          background-color: #f9f9f9;
        }
  
        td {
          border-left: 1px solid #BDB1B1;  
          height: 35px; 
          padding: 0 2px;
          vertical-align: middle;
          min-width: 100px;
  
      
          &:first-child {
            border-left-width: 0;
          }
        }
  
        &:last-child {
          border-bottom-width: 0;
        }
      }
    }
}

.table-p-1 {
    datatable-body-cell {
        padding: 5px !important;
    }
}

lp-application-item-details,
.tableWithInput {
  ngx-datatable {

    .datatable-body-cell {
      padding: 0 3px !important;
    }

    .datatable-body-row {
      cursor: default;
      min-height: 32px;

      .loading {
        background: $white;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 32px; 

        svg {
          display: inline-block;
          width: 28px;
          margin-right: 15px;
          margin-bottom: 2px;
        }
      }

      input {
        width: 100%;
        border: 0;
        background: transparent !important;
        padding: 4px 2px !important;
        height: 100%;

        &:hover {
          background: $white;
          background-size: 14px;
        }

        &:focus {
          border: 0 !important;
        }
      }

      .input-group-btn {
        width: 28px;
      }

      button {
        background: none;

        &:hover {
          background: none !important;
        }

        i {
          color: #000;
        }
      }
    }

    .applicationItemDetailAction {
      margin-left: -10px !important;    
    }

    .actioInTable {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;
      flex-direction: column;

      i {
        padding: 0 !important;
        width: 100%;
        margin: 0 !important;
        height: 28px !important;
      }
    }

    datatable-pager {
      .pager {
        i {
          font-size: 1.25em;
        }
      }
    }
  }
}

.table-hover>tbody>tr:hover {
  lp-time {
    span {
      position: relative;
      float: left;
      width: 100%;

      &:after {
        background-color: $lightdark !important;
      }
    }
  }
}

/** footer */
datatable-footer {
  height: 40px;
  background-color: $scheduler-black;
  padding: 0 10px;
  
   .datatable-pager li.active a {
    background-color: lighten($scheduler-black, 15%) !important;    
  }

  *:not(select):not(option) {
    color: $color-light-grey !important;
  }
  select + label {
    line-height: 1.4em;
  }

  datatable-pager {    
    flex: 1 1 50% !important;
  }

}

[class^="datatable-icon-"]:before,
[class*=" datatable-icon-"]:before {
  font-family: "FontAwesome" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-filter:before {
  content: "\f0b0";
}
.datatable-icon-collapse:before {
  content: "\f147";
}
.datatable-icon-expand:before {
  content: "\f196";
}
.datatable-icon-close:before {
  content: "\f2d3";
}
.datatable-icon-up:before {
  content: "\f0de";
}
.datatable-icon-down:before {
  content: "\f0dd";
}
.datatable-icon-sort:before {
  content: "\f0dc";
}
.datatable-icon-done:before {
  content: "\f00c";
}
.datatable-icon-done-all:before {
  content: "\f05d";
}
.datatable-icon-search:before {
  content: "\f002";
}
.datatable-icon-pin:before {
  content: "\f276";
}
.datatable-icon-add:before {
  content: "\f196";
}
.datatable-icon-left:before {
  content: "\f104";
}
.datatable-icon-right:before {
  content: "\f105";
}
.datatable-icon-skip:before {
  content: "\f101";
}
.datatable-icon-prev:before {
  content: "\f100";
}


/* LP-CELL : type boolean */
.boolean-true,
.boolean-false {
  height: 12px;
  width: 12px;
  @include border-radius(50px);
  border: 1px solid $dark;
  display:inline-block;
}

.boolean-true {
  background-color: $dark;
} 

.sort-desc{
  content: "\f0dd";
}
.sort-asc {
  content: "\f0de";
}

.table {
  > thead,
  > tbody,
  > .tfood {
      >tr>td.middle-align,
      >tr>th.middle-align {
        vertical-align: middle;
      }
  }
}

lp-modal-dashboard,
.datatable-body-cell {
  lp-active {
    background: transparent;
    border: 0;
    position: static;
    height: auto;
    text-align: left;

    > label {
      position: static;
      margin: 0 auto;
      display: block;
    }
  }
}
