
@font-face {
  font-family: "vega-custom-font";
  src:  url("fonts/icomoon.eot");
  src:  url('fonts/icomoon.eot?bkgox6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bkgox6') format('truetype'),
    url('fonts/icomoon.woff?bkgox6') format('woff'),
    url('fonts/icomoon.svg?bkgox6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="vega-"], [class*=" vega-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vega-custom-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

.vega-pricingDetailsHR:before {
  content: "\e97e";
}
.vega-pricingDetailsJR:before {
  content: "\e97f";
}

.vega-click:before {
  content: "\e95a";
}

.vega-addfast:before {
  content: "\e97d";
}

.vega-steering-wheel:before {
  content: "\e97c";
}

.vega-multi-product:before {
  content: "\e97b";
}

.vega-zoom:before {
  content: "\e97a";
}

.vega-fullsize-off:before {
  content: "\e978";
}

.vega-fullsize-on:before {
  content: "\e979";
}

.vega-pricingDetailsNat:before {
  content: "\e977";
}

.vega-rgpd:before {
  content: "\e976";
}

.vega-confidentiality:before {
  content: "\e974";
}

.vega-print-spooler:before {
  content: "\e974";
}

.vega-doc-departure:before {
  content: "\e973";
}

.vega-download:before {
  content: "\e972";
}
.vega-customerAccountingEvents:before {
  content: "\e96f";
}
.vega-accountingEvents:before {
  content: "\e970";
}
.vega-driverAccountingEvents:before {
  content: "\e971";
}
.vega-doc-cancellation:before {
  content: "\e96e";
}
.vega-stime_buyback:before {
  content: "\e96d";
}
.vega-duplicate:before {
  content: "\e96c";
}
.vega-pricing-times:before {
  content: "\e96b";
}
.vega-check2:before {
  content: "\e96a";
}
.vega-pricingDetailsLines:before {
  content: "\e969";
}
.vega-email:before {
  content: "\e966";
}
.vega-reset:before {
  content: "\e967";
}
.vega-home:before {
  content: "\e968";
}
.vega-theme:before {
  content: "\e965";
}
.vega-menu:before {
  content: "\e95d";
}
.vega-changePassword:before {
  content: "\e95e";
}
.vega-screenTest:before {
  content: "\e95f";
}
.vega-tasks:before {
  content: "\e960";
}
.vega-logout:before {
  content: "\e961";
}
.vega-languages:before {
  content: "\e962";
}
.vega-debugg:before {
  content: "\e963";
}
.vega-customization:before {
  content: "\e964";
}
.vega-relatedObject:before {
  content: "\e95c";
}
.vega-check:before {
  content: "\e95b";
}
.vega-check:before {
  content: "\e95b";
}
.vega-addLocation:before {
  content: "\e959";
}
.vega-warning:before {
  content: "\e958";
}
.vega-view:before {
  content: "\e957";
}
.vega-pencil:before {
  content: "\e956";
}
.vega-delete:before {
  content: "\e955";
}
.vega-edi:before {
  content: "\e94f";
}
.vega-workflows:before {
  content: "\e954";
}
.vega-traces:before {
  content: "\e950";
}
.vega-print:before {
  content: "\e951";
}
.vega-accordions:before {
  content: "\e952";
}
.vega-dustbin:before {
  content: "\e953";
}
.vega-attachment:before {
  content: "\e94e";
}
.vega-diskette:before {
  content: "\e94c";
}
.vega-add:before {
  content: "\e94d";
}
.vega-technicalSpecifications:before {
  content: "\e949";
}
.vega-circumstanceLinks:before {
  content: "\e94a";
}
.vega-third-parties-2:before {
  content: "\e94b";
}
.vega-mandates:before {
  content: "\e948";
}
.vega-crashLinks:before {
  content: "\e943";
}
.vega-kindsLinks:before {
  content: "\e944";
}
.vega-financialElementsLinks:before {
  content: "\e945";
}
.vega-facturationLinks:before {
  content: "\e946";
}
.vega-dateTime:before {
  content: "\e947";
}
.vega-search:before {
  content: "\e93a";
}
.vega-deposits:before {
  content: "\e93b";
}
.vega-invoiceLines:before {
  content: "\e93c";
}
.vega-medias:before {
  content: "\e93d";
}
.vega-openingHours:before {
  content: "\e93e";
}
.vega-calendar:before {
  content: "\e93f";
}
.vega-thirdPartyTypes:before {
  content: "\e940";
}
.vega-contacts:before {
  content: "\e941";
}
.vega-accountings:before {
  content: "\e942";
}
.vega-relations:before {
  content: "\e935";
}
.vega-ribs:before {
  content: "\e936";
}
.vega-registrations:before {
  content: "\e937";
}
.vega-additionalDrivers:before {
  content: "\e938";
}
.vega-historicalMileages:before {
  content: "\e939";
}
.vega-fines:before {
  content: "\e934";
}
.vega-equipments:before {
  content: "\e933";
}
.vega-examples:before {
  content: "\e92f";
}
.vega-quickQuotes:before {
  content: "\e930";
}
.vega-pricingDetails:before {
  content: "\e931";
}
.vega-damages:before {
  content: "\e932";
}
.vega-mainAccounts:before {
  content: "\e92b";
}
.vega-paymentMethods:before {
  content: "\e92c";
}
.vega-services:before {
  content: "\e92d";
}
.vega-addresses:before {
  content: "\e92e";
}
.vega-planning:before {
  content: "\e928";
}
.vega-preferences:before {
  content: "\e929";
}
.vega-models:before {
  content: "\e92a";
}
.vega-movements:before {
  content: "\e922";
}
.vega-creditNotes:before {
  content: "\e923";
}
.vega-changeEquipment:before {
  content: "\e924";
}
.vega-expenses:before {
  content: "\e925";
}
.vega-inspections:before {
  content: "\e926";
}
.vega-third-parties:before {
  content: "\e927";
}
.vega-users:before {
  content: "\e920";
}
.vega-locations:before {
  content: "\e921";
}
.vega-companies:before {
  content: "\e900";
}
.vega-driver:before {
  content: "\e91d";
}
.vega-customer:before {
  content: "\e91e";
}

.vega-forbidden:before {
  content: "\e91c";
}

.vega-driver-invoice-rebilling:before {
  content: "\e91a";
}
.vega-customer-invoice-rebilling:before {
  content: "\e91b";
}

.vega-invoice:before {
  content: "\e919";
}
.vega-invoices:before {
  content: "\e918";
}
.vega-icon-ml:before {
  content: "\e914";
}
.vega-icon-ltc:before {
  content: "\e915";
}
.vega-icon-mtc:before {
  content: "\e916";
}
.vega-icon-stc:before {
  content: "\e917";
}

.vega-customer-credit-note:before {
  content: "\e912";
}
.vega-driver-credit-note:before {
  content: "\e913";
}

.vega-customer-invoice-billing:before {
  content: "\e910";
}
.vega-driver-invoice-billing:before {
  content: "\e911";
}

.vega-cancel-return:before {
  content: "\e90f";
}

.vega-return:before {
  content: "\e90e";
}
.vega-customer-payment:before {
  content: "\e90b";
}
.vega-driver-payment:before {
  content: "\e90c";
}
.vega-payment:before {
  content: "\e90d";
}

.vega-ft:before {
  content: "\e904";
}

.vega-driver-invoices:before {
  content: "\e90a";
}

.vega-customer-invoices:before {
  content: "\e909";
}

.vega-driver-deposits:before {
  content: "\e907";
}
.vega-customer-deposits:before {
  content: "\e908";
}

.vega-customer-invoice:before {
  content: "\e906";
}

.vega-driver-invoice:before {
  content: "\e905";
}

.vega-diskette-stack:before {
  content: "\e903";
}

.vega-diskette:before {
  content: "\e902";
}

.vega-inspection-right:before {
  content: "\e901";
}

.vega-inspection-left:before {
  content: "\e91f";
}
