.technical-ft-search-btn ,
.pricing-detail-search-btn {
  position: fixed;
  z-index: 4;
  left: calc((100% - 50px) * 0.25 - 25px);
  bottom: 10px;
  font-size: 3em;
  height: 50px;
  cursor: pointer;  
  background: transparent;
  border: 0;
}
.technical-ft-save-btn,
.pricing-detail-save-btn {  
  background: transparent;
  border: 0;
  font-size: 3em;
  line-height: 1em;
  height: 31px;
  cursor: pointer;
  float: right;
  margin-right: 10px;
}
/*
.icon-tableSetings  {
  font-size: 2em;
  height: 35px;
  cursor: pointer;  
  position: absolute;
  right: 10px;
}*/

.DIVlistActiveColumn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  background: $white;
  z-index: 1;
  @include box-shadow( 0px -2px 10px 0px #333);
  overflow: auto;
  width: 400px;

  .menuitem {
    padding: 10px;
    border-bottom: 1px solid $lightgray2;    
  }
}