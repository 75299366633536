

$custom: #71b6f9;
$focusbtn: #80bdff;
// Color variables

$vega-gray: #282828;
$vega-primary: #008182;
$vega-orange: #ea6210;

$white: #ffffff;
$black: #000;

$success: #10c469;
$info: #35b8e0;
$light-blue: #71b6f9;
$warning: #ffff99;
$danger: #ff5b5b;
$purple: #5b69bc;
$blue: #3f51b5;
$pink: #ff8acc;
$purple: #e91e63;
$red: #ff2626;
$primary: $vega-primary;
$secondary: #727b84;
$inverse: #3b3e47;
$muted: #98a6ad;
$light: #eeeeee;
$dark: #424242;
$lightdark1: #1d2124;
$lightdark3: lighten($black, 20%);
$lightdark4: lighten($black, 27%);
$lightdark5: lighten($black, 33%);
$lightdark7: lighten($black, 47%);
$light-alt: lighten($black, 95%);
$lightdark-alt: lighten($black, 54%);
$lightdark: #f4f8fb;
$lightgray: #7a8c9a;
$lightgray2: #dae6ec;
$light5: #ebeff2;
$light3: #f3f3f3;
$light9: #f9f9f9;
$color-light-grey: #f1f1f1;

// scheduler colors
$scheduler-odd: #f0f0f0;
$scheduler-white: $white;
$scheduler-light-grey: $light;
$scheduler-grey: #9E9E9E;
$scheduler-black: #424242;
$scheduler-light-green: #A5D6A7;
$scheduler-green: #4CAF50;
$scheduler-light-yellow: #FFF59D;
$scheduler-yellow: #FFEB3B;
$scheduler-light-orange: #FFCC80;
$scheduler-orange: #FF9800;
$scheduler-light-red: #EF9A9A;
$scheduler-red: #F44336;
$scheduler-light-blue: #90CAF9;
$scheduler-blue: #2196F3;
$scheduler-theme-color: $primary;


// Width variables
$width: 100%;

// Height variables
$height: 100%;

$base-font-size: 14px;
$base-line-height: 20px;

//Font variables
$font-primary: 'Roboto', sans-serif;


$lp-forms-components: "lp-address, lp-time, lp-date-time, lp-date-time-range, lp-text-field, lp-date, lp-date-month, lp-dropdown-field, lp-number, lp-currency, lp-currency-v2, lp-zoom-field > div, lp-textarea-field, .modal-input, lp-zoom-field";

$lp-forms-components-error-exception: "lp-date-time, lp-date, lp-date-time-range, lp-date-month, lp-dropdown-field, lp-textarea-field";

$lp-forms-components-readonly: ".radio-readonly, lp-switch-box .disabled, lp-zoom-field .disabled, lp-dropdown-field .readonly, lp-date .readonly, lp-date-month .readonly, lp-date-time .readonly, lp-date .disabled, lp-date-month .disabled, lp-date-time .disabled";

$bg-overlay: rgba(39, 42, 43, 0.8);

/* Background colors */

.bg-custom {
    background-color: $custom !important;
  }
  
  .bg-primary {
    background-color: $primary !important;
  }
  
  .bg-success {
    background-color: $success !important;
  }
  
  .bg-info {
    background-color: $info !important;
  }
  
  .bg-warning {
    background-color: $warning !important;
  }
  
  .bg-danger {
    background-color: $danger !important;
  }
  
  .bg-muted {
    background-color: $lightdark !important;
  }
  
  .bg-inverse {
    background-color: $inverse !important;
  }
  
  .bg-purple {
    background-color: $purple !important;
  }
  
  .bg-pink {
    background-color: $pink !important;
  }
  
  .bg-white {
    background-color: $white !important;
  }
  
  .bg-lightdark {
    background-color: $lightdark !important;
  }
  
  /* Text colors */
  
  .text-custom {
    color: $custom !important;
  }
  
  .text-white {
    color: $white !important;
  }
  
  .text-danger {
    color: $danger !important;
  }
  
  .text-muted {
    color: $muted !important;
  }
  
  .text-primary {
    color: $primary !important;
  }
  
  .text-warning {
    color: $warning !important;
  }
  
  .text-success {
    color: $success !important;
  }
  
  .text-info {
    color: $info !important;
  }
  
  .text-inverse {
    color: $inverse !important;
  }
  
  .text-pink {
    color: $pink !important;
  }
  
  .text-purple {
    color: $purple !important;
  }
  
  .text-dark {
    color: $lightdark7 !important;
    &:focus,
    &:active {
      color: $lightdark1 !important;
    }
  }