router-outlet + lp-home, 
router-outlet + * > lp-top-banner + .container-fluid {
  padding-left: 60px;
}

.body-scheduler .scheduler {
  padding-left: 70px;
}

H1 {
    background: $logoBlanc center center no-repeat;
    left: 3px !important;
}

i {
  .mat-badge-content {
    font-family: $font-primary;
    font-size: $base-font-size;
  }
}

#home-container {
  background: none;
}

