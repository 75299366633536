router-outlet + lp-home, 
router-outlet + * > lp-top-banner + .container-fluid {
  padding-left: 60px;
}

.body-scheduler .scheduler {
  padding-left: 70px;
}

H1 {
    background: url(/assets/img/logo-irium-lp3k-white.png) no-repeat center center;
    left: 3px !important;
}

i {
  .mat-badge-content {
    font-family: $font-primary;
    font-size: $base-font-size;
  }
}