.carousel-memo,
.carousel-damage {
  .carousel {
    margin-bottom: 25px;    
  }
  .carousel-indicators {
    margin: 0;
    bottom: -25px;

    li {
      background-color: $primary;
    }
  }

  .carousel-control {
    width: 20px;
    cursor: pointer;
    text-align: center;

    &:hover {
      background-color: darken($white, 2%);
    }

    &.carousel-control-next {
      right: -20px;
    }

    &.carousel-control-prev {
      left: -20px;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
    background-image: none;
    font-family: "FontAwesome";
    font-weight: 900;
    color: $primary;
    }
    
    .carousel-control-prev-icon::before{
      content: "\f104";
    }
    
    .carousel-control-next-icon::before{
      content: "\f105";
    }
  }
}

.carousel-memo {
  .carousel {
    margin-bottom: 40px;
  }

  img {
    max-width: 100%;
    max-height: 150px;
    margin: 0 auto;
    display: block;
    cursor: zoom-in;
  }

  .icon-file {    
    text-align: center;
    font-size: 5em;
    height: 150px;
    @include vertical-align-middle();

    i.zoom {
      cursor: zoom-in;      
    }
  }

  p {
    i {
      font-size: 2.2em;
      background: darken($white, 10%);
      padding: 0;
      @include border-radius(50%);
      cursor: pointer;
      height: 40px;
      width: 40px;
      line-height: 40px;
      display: inline-block;

      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }


  .carousel-indicators {
    bottom: -40px;
    border-top: 1px solid $muted;
  }
}

.carousel-control {
  width: 10%;
  span {
    position: absolute;
    top: 50%;
    /* pushes the icon in the middle of the height */
    z-index: 5;
    display: inline-block;
    font-size: 30px;
  }
}